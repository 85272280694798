import { Box, Button, Container } from "@mui/material";
import axios from "axios";
import { collection, collectionGroup, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";
import { Loading } from "../themes/themes";

const accessToken =
    "EAAChyJKJ1i4BO3q9hB4Jo8HgoGws94Qx2UMhZCyqMM2qtadOBUiZCaXdP4ifnwoy9w2tMpRHPMlKJCnZCZCZA5XlhDV9XZBs8PPPnDaPZBtWZBgfpgmN8C0M5sdbCpiUCZA8IWnI9m3YQ4SAGfv4iU7RV77K0hIrfmizfmWMnfIDKfDFuwPA0cNa2lfGd9xSDTry64NX3NiIBUerd";

export default function TestPage() {
    const [ids, setIds] = useState(["8469426686444997"]);
    const [loading, setLoading] = useState(false);

    const handleGetIds = async () => {
        const collectionRef = collection(db, "fbMessages");
        const q = query(collectionRef, where("pageId", "==", "109949321829653"));
        setLoading(true);
        const snapshot = await getDocs(q);
        const chatRooms = mapSnapshot(snapshot);
        console.log(chatRooms);

        const ids = chatRooms.map((chatRoom) => chatRoom.id.split("-")[1]);
        setIds(ids);

        console.log(ids);

        let myMessages = [];
        await Promise.all(
            chatRooms.map(async (chatRoom) => {
                const collectionRef = collection(db, "fbMessages", chatRoom.id, "messages");
                const q = query(collectionRef, where("body", "==", "Poll - Chosen option: Get Started"));
                const snapshot = await getDocs(q);
                const messages = mapSnapshot(snapshot);

                if (messages.length > 0) {
                    myMessages.push(messages[0]);
                }
            })
        );
        console.log(myMessages);
        setLoading(false);
    };

    const handleTest = async () => {
        ids.forEach(async (id) => {
            let data = JSON.stringify({
                recipient: {
                    id,
                },
                metadata: "Passing thread control to the default application",
            });

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `https://graph.facebook.com/v21.0/me/pass_thread_control?access_token=${accessToken}`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            try {
                const response = await axios.request(config);
                console.log(JSON.stringify(response.data), id);
            } catch (error) {
                console.log(error?.response?.data?.error?.message, id);

                // let data = JSON.stringify({
                //     messaging_type: "RESPONSE",
                //     thread_control: {
                //         app_id: "177882824037934",
                //         control_type: "pass",
                //     },
                //     recipient: {
                //         id,
                //     },
                //     message: {
                //         text: "_",
                //     },
                // });

                // let config = {
                //     method: "post",
                //     maxBodyLength: Infinity,
                //     url: `https://graph.facebook.com/v21.0/me/messages?access_token=${accessToken}`,
                //     headers: {
                //         "Content-Type": "application/json",
                //     },
                //     data: data,
                // };

                // try {
                //     const response = await axios.request(config);
                //     console.log(JSON.stringify(response.data), id);
                // } catch (error) {
                //     console.log(error?.response?.data?.error?.message, id);
                // }
            }
        });
    };

    const handleSearch = async () => {
        const collectionRef = collectionGroup(db, "messages");
        const q = query(collectionRef, where("body", "==", "Poll - Chosen option: Get Started"));

        setLoading(true);
        const snapshot = await getDocs(q);
        const messages = mapSnapshot(snapshot);
        console.log(messages);
    };

    return (
        <Container>
            <Box m={1} display={"flex"} gap={1} justifyContent={"flex-start"} flexDirection={"column"}>
                <Box width={"100%"}>
                    <Loading loading={loading} />
                </Box>
                <Box>
                    <Button variant="contained" onClick={handleGetIds}>
                        Get Ids
                    </Button>
                </Box>
                <Box>
                    <Button variant="contained" onClick={handleTest}>
                        Call API
                    </Button>
                </Box>
                <Box>
                    <Button variant="contained" onClick={handleSearch}>
                        Search
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
