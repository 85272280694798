import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import TestComponent from "./TestComponent";

export default function Test2Page() {
    const [counter, setCounter] = React.useState(0);
    const [display, setDisplay] = React.useState("");

    const handleClick = (param) => {
        console.log(param);
        setDisplay(param);
    };

    return (
        <Container>
            <Box>
                <TestComponent counter={counter} handleClick={handleClick} setCounter={setCounter} />
            </Box>
            <Box>
                <Button onClick={() => setCounter(counter + 1)}>Add</Button>
            </Box>
            <Box>
                <Typography>{display}</Typography>
            </Box>
        </Container>
    );
}
