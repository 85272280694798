import {
    Box,
    Button,
    Dialog,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { db, functions } from "../../firebase/firebase-utils";
import formatNumber from "../../functions/common-functions/formatNumber";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";
import { PaymentInfo, PriceInfo, ProductInfo, SubscriptionInfo } from "./types";

interface CreateStripeCustomerResponse {
    customerId: string;
}

export default function PaymentDialog({
    openAddPayment,
    setOpenAddPayment,
    currentRow,
    setTrigger,
}: {
    openAddPayment: boolean;
    setOpenAddPayment: any;
    currentRow: any;
    setTrigger: any;
}) {
    const [paymentDate, setPaymentDate] = useState<Dayjs | null>(dayjs(new Date()));
    const [amount, setAmount] = useState<string>("");
    const [products, setProducts] = useState<ProductInfo[]>([]);
    const [prices, setPrices] = useState<PriceInfo[]>([]);
    const [currentProductId, setCurrentProductId] = useState<string | "">("");
    const [currentProduct, setCurrentProduct] = useState<ProductInfo | null>(null);
    const [currentPrices, setCurrentPrices] = useState<PriceInfo[] | null>(null);
    const [currentPriceId, setCurrentPriceId] = useState<string | "">("");
    const [subcriptions, setSubscriptions] = useState<SubscriptionInfo[] | null>(null);
    const [currentSubscriptionId, setCurrentSubscriptionId] = useState<string | "">("");

    useEffect(() => {
        if (!currentRow?.id) return;

        const subscriptions: SubscriptionInfo[] = currentRow.subscriptions?.filter(
            (subscription: SubscriptionInfo) => subscription?.type === "manual"
        );

        if (arrayIsEmpty(subscriptions)) return;

        setSubscriptions(subscriptions);
    }, [currentRow]);

    useEffect(() => {
        if (!currentSubscriptionId) return;

        const subscription = subcriptions?.find((subscription) => subscription.id === currentSubscriptionId);
        if (!subscription) return;

        const priceId = subscription.priceId;
        if (priceId) {
            setCurrentPriceId(priceId);
        }

        const productId = subscription.productId;
        if (productId) {
            setCurrentProductId(productId);
        }
    }, [currentSubscriptionId]);

    useEffect(() => {
        const loadData = async () => {
            const collectionRef = collection(db, "products");
            const q = query(collectionRef, where("type", "==", "djc"));
            const snapshot = await getDocs(q);
            const products: ProductInfo[] = mapSnapshotTS(snapshot);
            setProducts(products);

            const prices: PriceInfo[] = [];
            await Promise.all(
                products.map(async (product) => {
                    if (!product.id) return;
                    const pricesRef = collection(db, "products", product.id, "prices");
                    const q = query(pricesRef, where("active", "==", true));
                    const pricesSnap = await getDocs(q);
                    const pricesDocs: PriceInfo[] = mapSnapshotTS(pricesSnap);
                    console.log("pricesDocs", pricesDocs);
                    prices.push(...pricesDocs);
                })
            );
            setPrices(prices);
        };

        loadData();
    }, []);

    useEffect(() => {
        if (currentProductId) {
            const currentPrices = prices.filter((price) => price.product === currentProductId);
            setCurrentPrices(currentPrices);

            const currentProduct: ProductInfo | undefined = products.find(
                (product) => product.id === currentProductId
            );

            if (currentProduct) {
                setCurrentProduct(currentProduct);
            }
        }
    }, [currentProductId]);

    const handleSubmit = async () => {
        if (!currentRow?.id) return;

        if (!currentPriceId) {
            toast.error("Please select price");
            return;
        }
        if (!paymentDate) {
            toast.error("Please select payment date");
            return;
        }
        if (!amount) {
            toast.error("Please enter amount");
            return;
        }
        if (!currentProductId) {
            toast.error("Please select a product");
            return;
        }

        const price = prices.find((price) => price.id === currentPriceId);
        if (!price) return;

        let customerId = currentRow.customerId;

        if (!currentSubscriptionId) {
            let subscriptionRef;

            // check if stripe customer account is created
            if (!customerId) {
                console.log("Customer not found");
                const createStripeCustomer = httpsCallable<unknown, CreateStripeCustomerResponse>(
                    functions,
                    "createStripeCustomer"
                );

                try {
                    const { data } = await createStripeCustomer({
                        name: currentRow.displayName,
                        email: currentRow.email,
                        metadata: { firebaseUID: currentRow.uid },
                    });

                    customerId = data?.customerId;
                    console.log("customerId", customerId);
                    subscriptionRef = collection(db, "customers", customerId, "subscriptions");

                    if (!customerId) {
                        toast.error("Error creating customer");
                        return;
                    }
                } catch (error) {
                    console.error("Error creating customer", error);
                    return;
                }
            } else {
                subscriptionRef = collection(db, "customers", customerId, "subscriptions");
            }

            // create new subscription
            const subscriptionData: SubscriptionInfo = {
                amount: parseInt((parseFloat(amount) * 100).toString()),
                created: paymentDate.toDate(),
                current_period_end:
                    price.interval === "month"
                        ? paymentDate.add(1, "month").toDate()
                        : paymentDate.add(1, "year").toDate(),
                current_period_start: paymentDate.toDate(),
                currency: price.currency,
                description: price.description,
                items: [
                    {
                        plan: {
                            id: currentPriceId,
                            active: true,
                            amount: parseInt((parseFloat(amount) * 100).toString()),
                            currency: price.currency,
                            interval: price.interval,
                            interval_count: 1,
                            product: currentProductId,
                        },
                    },
                ],
                interval: price.interval,
                status: "active",
                priceId: currentPriceId,
                productId: currentProductId,
                product: currentProduct?.ref,
                price: price.ref,
                prices: [price.ref],
                type: "manual",
            };

            console.log("subscriptionData", subscriptionData);
            const { id } = await addDoc(subscriptionRef, subscriptionData);
            console.log("Subscription added with ID: ", id);
            toast.success("Subscription added successfully");
        }

        const paymentRef = collection(db, "customers", customerId, "payments");

        const paymentData: PaymentInfo = {
            amount: parseInt((parseFloat(amount) * 100).toString()),
            amount_received: parseInt((parseFloat(amount) * 100).toString()),
            created: paymentDate.unix(),
            currency: price.currency,
            priceId: currentPriceId,
            prices: [price.ref],
            customer: customerId,
            type: "manual",
        };

        console.log("paymentData", paymentData);

        const { id } = await addDoc(paymentRef, paymentData);
        console.log("Payment added with ID: ", id);
        const docRef = doc(db, "customers", customerId, "payments", id);
        await updateDoc(docRef, { id });
        setTrigger((prev: number) => prev + 1);
        toast.success("Payment added successfully");
        setAmount("");
        setPaymentDate(dayjs(new Date()));
        setCurrentPriceId("");
        setCurrentProductId("");
        setOpenAddPayment(false);
    };

    return (
        <Dialog
            open={openAddPayment}
            onClose={() => setOpenAddPayment(false)}
            PaperProps={{ sx: { width: ["100vw", "40vw"] } }}
            maxWidth="lg"
        >
            <Box p={2} display="flex" flexDirection={"column"} gap={2}>
                <Typography>Add Payment</Typography>
                {!subcriptions ? (
                    <>
                        <Select
                            onChange={(e: SelectChangeEvent) => setCurrentProductId(e.target.value)}
                            displayEmpty
                            value={currentProductId}
                        >
                            <MenuItem value="" disabled>
                                Select Subscription
                            </MenuItem>
                            {products.map((product) => (
                                <MenuItem key={product.id} value={product.id}>
                                    {product.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {currentPrices && (
                            <Select
                                onChange={(e: SelectChangeEvent) => setCurrentPriceId(e.target.value)}
                                value={currentPriceId}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    Select price
                                </MenuItem>
                                {currentPrices.map((price) => (
                                    <MenuItem key={price.id} value={price.id}>
                                        {`${price.description} - ${price.currency} ${formatNumber(
                                            price.unit_amount / 100
                                        )}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </>
                ) : (
                    <>
                        <Select
                            onChange={(e: SelectChangeEvent) => setCurrentSubscriptionId(e.target.value)}
                            value={currentSubscriptionId}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>
                                Select subscription
                            </MenuItem>
                            {subcriptions?.map((subscription) => (
                                <MenuItem key={subscription.id} value={subscription.id}>
                                    {subscription.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                )}

                <TextField
                    fullWidth
                    label="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
                <DatePicker value={paymentDate} onChange={(newValue) => setPaymentDate(newValue)} />
                <Button variant="contained" color="success" onClick={handleSubmit}>
                    Submit
                </Button>
            </Box>
        </Dialog>
    );
}
