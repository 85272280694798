import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    Icon,
    IconButton,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import {
    addDoc,
    arrayUnion,
    collection,
    doc,
    onSnapshot,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { useInput } from "../../context-utils/InputContext";
import useList from "../../context-utils/ListContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import convertDate from "../../functions/common-functions/convertDate";
import useChooseFriend from "../../hooks/useChooseFriend";
import useWindowSize from "../../hooks/useWindowSize";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";
import { Case, ICustomField } from "./CasesPage";

export type CustomerProduct = {
    id: string;
    name: string;
};

type Role = "Viewer" | "Editor";

type SharedUser = {
    id: string;
    name: string;
    role: "Viewer" | "Editor";
};

export default function AddCase({
    companyId,
    currentCase,
    setCurrentCaseId,
}: {
    companyId: string | null;
    currentCase: Case | null;
    setCurrentCaseId: any;
}): React.JSX.Element {
    const { user } = useContext(AuthContext);

    const [date, setDate] = useState<Dayjs | null>(dayjs(new Date()));
    const [products, setProducts] = useState<CustomerProduct[]>([]);
    const [currentProductId, setCurrentProductId] = useState<string | null>(null);
    const [currentProduct, setCurrentProduct] = useState<CustomerProduct | null>(null);
    const [prospectName, setProspectName] = useState<string>("");
    const [prospectPhone, setProspectPhone] = useState<string>("");
    const [prospectEmail, setProspectEmail] = useState<string>("");
    const [agentIds, setAgentIds] = useState<string[]>([]);
    const [agentNames, setAgentNames] = useState<string[]>([]);
    const [percentages, setPercentages] = useState<string[]>([]);
    const [customValues, setCustomValues] = useState<string[]>([]);
    const [customRemarks, setCustomRemarks] = useState<string[]>([]);
    const [remark, setRemark] = useState<string>("");
    const [value, setValue] = useState<string>("");
    const [viewerIds, setViewerIds] = useState<string[]>([]);
    const [viewerNames, setViewerNames] = useState<string[]>([]);
    const [editorIds, setEditorIds] = useState<string[]>([]);
    const [editorNames, setEditorNames] = useState<string[]>([]);

    const [customFields, setCustomFields] = useState<ICustomField[]>([]);

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [newFieldLabel, setNewFieldLabel] = useState<string>("");
    const [newFieldType, setNewFieldType] = useState<"string" | "number">("string");

    const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
    // Initialize viewerRoles with the correct type
    const [userRoles, setUserRoles] = useState<Role[]>([]);

    const [sharedUsers, setSharedUsers] = useState<SharedUser[]>([]);

    const [companyData, setCompanyData] = useState<any>(null);

    const windowSize = useWindowSize();
    const list = useList();
    const input = useInput();
    const friendSelector = useChooseFriend();
    const confirmation = useConfirmation();

    const isMobile = useMemo(() => {
        if (windowSize.width) {
            return windowSize.width < 600;
        } else {
            return false;
        }
    }, [windowSize.width]);

    useEffect(() => {
        if (companyId) {
            // fetch products
            const collectionRef = collection(db, "customerProducts");
            const q = query(collectionRef, where("companyId", "==", companyId));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const products = mapSnapshotTS<CustomerProduct>(querySnapshot);

                setProducts(products);
            });

            return unsubscribe;
        }
    }, [companyId]);

    useEffect(() => {
        if (companyId) {
            const docRef = doc(db, "companies", companyId);
            const unsubscribe = onSnapshot(docRef, (doc) => {
                const companyData = mapDocSnapshot(doc);
                console.log(companyData);
                setCompanyData(companyData);
            });
            return unsubscribe;
        }
    }, [companyId, currentCase]);

    useEffect(() => {
        if (!currentProductId) {
            setCurrentProduct(null);
            return;
        }

        const docRef = doc(db, "customerProducts", currentProductId);
        const unsubscribe = onSnapshot(docRef, (doc: any) => {
            const product = mapDocSnapshot(doc);
            setCurrentProduct(product);
        });

        return unsubscribe;
    }, [currentProductId]);

    useEffect(() => {
        console.log("currentCase", currentCase?.customFields);
    }, [currentCase]);

    useEffect(() => {
        // fill in the form if currentCase is not null
        if (!currentCase) {
            // reset form
            setDate(dayjs(new Date()));
            setProspectName("");
            setProspectPhone("");
            setProspectEmail("");
            setCurrentProductId(null);
            setValue("");
            setAgentIds([]);
            setAgentNames([]);
            setViewerIds([]);
            setViewerNames([]);
            setEditorIds([]);
            setEditorNames([]);
            setPercentages([]);
            setCustomValues([]);
            setCustomRemarks([]);
            setRemark("");
            setSharedUsers([]); // Reset shared users
            setCustomFields(companyData?.customFields || []); // Reset custom fields

            return;
        }

        setDate(dayjs(convertDate(currentCase.date)));
        setProspectName(currentCase.prospectName);
        setProspectPhone(currentCase.prospectPhone);
        setProspectEmail(currentCase.prospectEmail);
        setCurrentProductId(currentCase.productId);
        setValue(currentCase.value);
        setAgentIds(currentCase?.agentIds || []);
        setAgentNames(currentCase?.agentNames || []);
        setViewerIds(currentCase?.viewerIds || []);
        setViewerNames(currentCase?.viewerNames || []);
        setPercentages(currentCase.percentages);
        setCustomValues(currentCase.customValues);
        setCustomRemarks(currentCase.customRemarks);
        setRemark(currentCase.remark);
        setEditorIds(currentCase.editorIds || []);
        setEditorNames(currentCase.editorNames || []);

        // Handle custom fields
        const mergedFields: ICustomField[] = [];

        // First add company fields
        if (companyData?.customFields) {
            mergedFields.push(...companyData.customFields.map((field: ICustomField) => ({ ...field })));
        }

        // Then add case fields
        if (currentCase.customFields) {
            currentCase.customFields.forEach((caseField) => {
                const existingIndex = mergedFields.findIndex((f) => f.label === caseField.label);
                if (existingIndex >= 0) {
                    // Update existing field value
                    mergedFields[existingIndex] = { ...mergedFields[existingIndex], value: caseField.value };
                } else {
                    // Add new field
                    mergedFields.push({ ...caseField });
                }
            });
        }

        // Remove duplicates while preserving the most recent values
        const uniqueFields = mergedFields.reduce((acc: ICustomField[], current) => {
            const existingIndex = acc.findIndex((item) => item.label === current.label);
            if (existingIndex === -1) {
                acc.push(current);
            }
            return acc;
        }, []);

        setCustomFields(uniqueFields);

        // Combine viewer and editor data into sharedUsers
        const updatedSharedUsers = [
            ...(currentCase.viewerIds || []).map((id: string, index: number) => ({
                id,
                name: currentCase.viewerNames?.[index] || "",
                role: "Viewer" as const,
            })),
            ...(currentCase.editorIds || []).map((id: string, index: number) => ({
                id,
                name: currentCase.editorNames?.[index] || "",
                role: "Editor" as const,
            })),
            ...(currentCase.agentIds || [])
                .filter((id) => !(currentCase.viewerIds || []).includes(id)) // Exclude agents already in viewerIds
                .map((id, index) => ({
                    id,
                    name: currentCase.agentNames?.[index] || "",
                    role: "Viewer" as const, // Default agents to Viewer role with 'as const'
                })),
        ];

        // Ensure unique users in sharedUsers based on id
        const uniqueSharedUsers = updatedSharedUsers.filter(
            (user, index, self) => index === self.findIndex((u) => u.id === user.id)
        );

        setSharedUsers(uniqueSharedUsers);
    }, [currentCase, companyData]);

    const handleSelectProduct = async () => {
        const response = await list(products, "Select Product", "name", "inventory", true, true);

        if (!response) return;

        if (response === "addAccount") {
            const productName = await input("Add new product", "Enter the product name", "Product name", "");
            if (productName) {
                // add new product
                const collectionRef = collection(db, "customerProducts");
                const { id } = await addDoc(collectionRef, {
                    name: productName,
                    companyId,
                    date: new Date(),
                    createdBy: user.id,
                });
                setCurrentProductId(id);
                toast.success("Product added successfully");
            }
        } else {
            setCurrentProductId(response.id);
        }
    };

    const handleAddAgent = async () => {
        //
        const response = await friendSelector();

        if (response) {
            const agentId = response.id;
            const agentName = response.displayName;
            // Check if agentId already exists
            if (!agentIds.includes(agentId)) {
                // Add to agent lists
                setAgentIds([...agentIds, agentId]);
                setAgentNames([...agentNames, agentName]);

                // Add to sharedUsers with Viewer role by default if not already in sharedUsers
                if (!sharedUsers.some((user) => user.id === agentId)) {
                    setSharedUsers((prevSharedUsers) => [
                        ...prevSharedUsers,
                        { id: agentId, name: agentName, role: "Viewer" as const },
                    ]);
                    // Also add to viewerIds since default role is Viewer
                    setViewerIds((prev) => [...prev, agentId]);
                    setViewerNames((prev) => [...prev, agentName]);
                }
            } else {
                toast.error("Agent already exists");
            }
        }
    };

    const handleRemoveAgent = (index: number) => {
        const agentIdToRemove = agentIds[index];
        const newAgentIds = agentIds.filter((_, i) => i !== index);
        const newAgentNames = agentNames.filter((_, i) => i !== index);
        const newPercentages = percentages.filter((_, i) => i !== index);
        const newCustomValues = customValues.filter((_, i) => i !== index);
        const newRemarks = customRemarks.filter((_, i) => i !== index);

        setAgentIds(newAgentIds);
        setAgentNames(newAgentNames);
        setPercentages(newPercentages);
        setCustomValues(newCustomValues);
        setCustomRemarks(newRemarks);

        // Remove from viewerIds/editorIds and update sharedUsers based on their role
        const user = sharedUsers.find((u) => u.id === agentIdToRemove);
        if (user) {
            if (user.role === "Viewer") {
                setViewerIds((prev) => prev.filter((id) => id !== agentIdToRemove));
                setViewerNames((prev) => prev.filter((_, i) => viewerIds[i] !== agentIdToRemove));
            } else if (user.role === "Editor") {
                setEditorIds((prev) => prev.filter((id) => id !== agentIdToRemove));
                setEditorNames((prev) => prev.filter((_, i) => editorIds[i] !== agentIdToRemove));
            }
            setSharedUsers((prev) => prev.filter((u) => u.id !== agentIdToRemove));
        }
    };

    if (!companyId) {
        return (
            <Box p={1}>
                <Typography>Select a company first</Typography>
            </Box>
        );
    }

    const handleSaveCase = async () => {
        if (!date) {
            toast.error("Please fill in date");
            return;
        }

        if (!prospectName) {
            toast.error("Please fill in Propsect Name");
            return;
        }

        const viewers = sharedUsers
            .filter((user) => user.role === "Viewer")
            .map((user) => ({
                id: user.id,
                name: user.name,
            }));

        const editors = sharedUsers
            .filter((user) => user.role === "Editor")
            .map((user) => ({
                id: user.id,
                name: user.name,
            }));

        const data = {
            date: date ? date.toDate() : new Date(),
            prospectName,
            prospectEmail,
            prospectPhone,
            productId: currentProductId,
            value,
            agentIds,
            agentNames,
            viewerIds: viewers.map((v) => v.id),
            viewerNames: viewers.map((v) => v.name),
            editorIds: editors.map((e) => e.id),
            editorNames: editors.map((e) => e.name),
            percentages,
            customValues,
            customRemarks,
            remark,
            companyId,
            createdBy: user.id,
            created: new Date(),
            customFields,
        };

        try {
            if (currentCase?.id) {
                // Update existing case
                const caseRef = doc(db, "cases", currentCase.id);
                await setDoc(caseRef, data, { merge: true });

                // Update companyIds for each user role: agents, viewers, and editors
                await Promise.all([
                    ...agentIds.map(async (agentId) => {
                        const userRef = doc(db, "users", agentId);
                        await updateDoc(userRef, { companyIds: arrayUnion(companyId) });
                    }),
                    ...viewerIds.map(async (viewerId) => {
                        const userRef = doc(db, "users", viewerId);
                        await updateDoc(userRef, { companyIds: arrayUnion(companyId) });
                    }),
                    ...editorIds.map(async (editorId) => {
                        // Update companyIds for editors
                        const userRef = doc(db, "users", editorId);
                        await updateDoc(userRef, { companyIds: arrayUnion(companyId) });
                    }),
                ]);
                toast.success("Case updated successfully");
            } else {
                // Add new case
                const collectionRef = collection(db, "cases");
                const { id } = await addDoc(collectionRef, { ...data, admins: [user.id] });

                // Update the case document with its own ID
                const caseRef = doc(db, "cases", id);
                await updateDoc(caseRef, { caseId: id });

                setCurrentCaseId(id);

                // Update companyIds for each user role: agents, viewers, and editors
                await Promise.all([
                    ...agentIds.map(async (agentId) => {
                        const userRef = doc(db, "users", agentId);
                        await updateDoc(userRef, { companyIds: arrayUnion(companyId) });
                    }),
                    ...viewerIds.map(async (viewerId) => {
                        const userRef = doc(db, "users", viewerId);
                        await updateDoc(userRef, { companyIds: arrayUnion(companyId) });
                    }),
                    ...editorIds.map(async (editorId) => {
                        // Update companyIds for editors
                        const userRef = doc(db, "users", editorId);
                        await updateDoc(userRef, { companyIds: arrayUnion(companyId) });
                    }),
                ]);
                toast.success("Case added successfully");
            }

            // Save customFieldLabels into the company document
            const companyRef = doc(db, "companies", companyId);
            const newCustomFields = companyData.customFields
                ? [...companyData.customFields, ...customFields]
                : customFields;

            console.log(newCustomFields);
            // remove duplicates based on label
            const uniqueCustomFields = newCustomFields.filter(
                (field, index, self) => index === self.findIndex((f) => f.label === field.label)
            );

            uniqueCustomFields.forEach((field) => {
                field.value = "";
            });

            console.log(uniqueCustomFields);
            await updateDoc(companyRef, {
                customFields: uniqueCustomFields, // Save customFieldLabels to the company
            });
        } catch (error) {
            toast.error("Error saving case");
        }
    };

    const handleAddViewer = async () => {
        const response = await friendSelector();
        if (response) {
            const viewerId = response.id;
            const viewerName = response.displayName;

            // Check if viewerId already has a role, if so, don't add as Viewer
            const existingUser = sharedUsers.find((user) => user.id === viewerId);
            if (existingUser) {
                toast.error("User already has a role assigned");
                return;
            }

            // Add to viewer list and sharedUsers as Viewer role
            setViewerIds([...viewerIds, viewerId]);
            setViewerNames([...viewerNames, viewerName]);
            setSharedUsers((prev) => [...prev, { id: viewerId, name: viewerName, role: "Viewer" as const }]);
        }
    };

    // Function to remove a user from all roles
    const handleRemoveUser = (userId: string) => {
        // Check if user is an agent
        if (agentIds.includes(userId)) {
            toast.error("Cannot remove user as they are an agent");
            return;
        }

        // Only remove from sharedUsers and viewer/editor lists if they're not an agent
        setSharedUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
        setViewerIds((prev) => prev.filter((id) => id !== userId));
        setEditorIds((prev) => prev.filter((id) => id !== userId));
    };

    const isAdminOrEditor = () => {
        if (currentCase?.admins?.includes(user.id) || currentCase?.editorIds?.includes(user.id)) {
            return true;
        }
        return false;
    };
    const isCompanyAdminOnly = () => {
        if (companyData?.admins?.includes(user.id)) {
            return true;
        }
        return false;
    };

    const handleAddCustomField = () => {
        if (newFieldLabel) {
            // Convert newFieldLabel to lowercase for case-insensitive comparison
            const labelExists = customFields.some(
                (field) => field.label.toLowerCase() === newFieldLabel.toLowerCase()
            );

            if (labelExists) {
                toast.error("This field name already exists. Please enter a unique field name.");
            } else {
                setCustomFields((prev) => [...prev, { label: newFieldLabel, type: newFieldType, value: "" }]);
                setNewFieldLabel("");
                setDialogOpen(false); // Close the dialog
            }
        } else {
            toast.error("Please enter a field name.");
        }
    };

    // Function to delete a custom field
    const handleRemoveCustomField = async (index: number) => {
        const response = await confirmation(
            "Are you sure you want to delete this custom field? It will delete from company template as well",
            "Press ok to proceed"
        );

        if (response) {
            const newCustomFields = [...customFields];

            newCustomFields.splice(index, 1);
            console.log(newCustomFields);

            const docRef = doc(db, "companies", companyId);
            await setDoc(docRef, { customFields: newCustomFields }, { merge: true });

            setCustomFields(newCustomFields);
        }
    };

    // // Open the share dialog
    // const handleOpenShareDialog = () => setShareDialogOpen(true);

    const handleShareDialogOpen = () => setShareDialogOpen(true);

    // Handle role change within the sharedUsers array
    const handleRoleChange = (userId: string, newRole: "Viewer" | "Editor") => {
        setSharedUsers((prevUsers) =>
            prevUsers.map((user) => (user.id === userId ? { ...user, role: newRole } : user))
        );

        // Update viewer or editor lists accordingly
        if (newRole === "Viewer") {
            setViewerIds((prev) => (prev.includes(userId) ? prev : [...prev, userId]));
            setEditorIds((prev) => prev.filter((id) => id !== userId));
        } else if (newRole === "Editor") {
            setEditorIds((prev) => (prev.includes(userId) ? prev : [...prev, userId]));
            setViewerIds((prev) => prev.filter((id) => id !== userId));
        }
    };

    return (
        <Box p={1} width={"100%"} display="flex" flexDirection={"column"} gap={1}>
            <Box display="flex" justifyContent="flex-end">
                <Button
                    variant="contained"
                    onClick={handleShareDialogOpen}
                    color="primary"
                    startIcon={<ShareIcon />}
                    sx={{
                        borderRadius: 2, // Adjust the border radius
                        padding: "8px 16px", // Adjust the padding
                        fontWeight: "bold", // Make the text bold
                    }}
                >
                    Share
                </Button>
            </Box>

            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Typography sx={{ width: "300px" }}>Date: </Typography>
                <DatePicker value={date} onChange={(newValue) => setDate(newValue)} sx={{ width: "100%" }} />
            </Box>
            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Typography sx={{ width: "300px" }}>Prospect Name (required): </Typography>
                <TextField
                    size="small"
                    value={prospectName}
                    required
                    variant="outlined"
                    onChange={(e) => {
                        setProspectName(e.target.value);
                    }}
                    fullWidth
                />
            </Box>
            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Typography sx={{ width: "300px" }}>Prospect Phone: </Typography>
                <TextField
                    size="small"
                    value={prospectPhone}
                    variant="outlined"
                    onChange={(e) => {
                        setProspectPhone(e.target.value);
                    }}
                    fullWidth
                />
            </Box>
            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Typography sx={{ width: "300px" }}>Prospect Email: </Typography>
                <TextField
                    size="small"
                    value={prospectEmail}
                    variant="outlined"
                    onChange={(e) => {
                        setProspectEmail(e.target.value);
                    }}
                    fullWidth
                />
            </Box>
            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Typography sx={{ width: "300px" }}>Product: </Typography>
                {currentProduct ? (
                    <Box display="flex" gap={1} alignItems={"center"}>
                        <Typography>{currentProduct.name}</Typography>
                        <IconButton size="small" onClick={() => setCurrentProductId("")}>
                            <Icon>clear</Icon>
                        </IconButton>
                    </Box>
                ) : (
                    <Button variant="contained" size="small" onClick={handleSelectProduct}>
                        Select Product
                    </Button>
                )}
            </Box>
            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Typography sx={{ width: "300px" }}>Value: </Typography>
                <TextField
                    size="small"
                    value={value}
                    variant="outlined"
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    fullWidth
                />
            </Box>

            {/* Custom fields */}
            {customFields.map((field, index) => (
                <Box key={field.label} display="flex" alignItems="center" gap={1} mb={1}>
                    <Typography sx={{ width: "300px" }}>
                        {field.label}:{" "}
                        {isCompanyAdminOnly() && (
                            <IconButton onClick={() => handleRemoveCustomField(index)}>
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Typography>

                    <TextField
                        size="small"
                        value={field.value} // Bind the value to customFieldValues
                        variant="outlined"
                        onChange={(e) => {
                            setCustomFields((prev) => {
                                const newFields = [...prev];
                                newFields[index].value = e.target.value;
                                return newFields;
                            });
                        }}
                        fullWidth
                    />
                </Box>
            ))}

            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Tooltip title={isCompanyAdminOnly() ? "" : "Only admins can add custom fields"}>
                    <span>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => setDialogOpen(true)}
                            disabled={!isCompanyAdminOnly()}
                        >
                            Add Custom Field
                        </Button>
                    </span>
                </Tooltip>
            </Box>

            <Box width="100%" my={1}>
                <Divider />
            </Box>
            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Button variant="contained" size="small" onClick={handleAddAgent}>
                    Add Agent
                </Button>
            </Box>
            <Box display="flex" flexDirection={"column"}>
                {agentNames.map((agentName, index) => (
                    <Box
                        display="flex"
                        gap={1}
                        alignItems={"center"}
                        key={index}
                        justifyContent="space-between"
                        mb={1}
                        width={"100%"}
                    >
                        <Grid container display={"flex"} alignItems={"center"}>
                            <Grid item xs={12} md={4}>
                                <Box display="flex" gap={1} alignItems={"center"} width={"300px"}>
                                    <Typography sx={{ marginRight: 2 }}>{`Agent ${index + 1}: `}</Typography>
                                    <Typography flex={1}>{agentName}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Box display="flex" alignItems="center" gap={1} my={1}>
                                    <TextField
                                        size="small"
                                        label="%"
                                        variant="outlined"
                                        sx={{ width: "30%" }}
                                        value={percentages[index] || ""}
                                        onChange={(e) => {
                                            const newPercentages = [...percentages];
                                            newPercentages[index] = e.target.value;
                                            setPercentages(newPercentages);
                                        }}
                                    />
                                    <TextField
                                        size="small"
                                        label="Custom"
                                        variant="outlined"
                                        sx={{ width: "30%" }}
                                        value={customValues[index] || ""}
                                        onChange={(e) => {
                                            const newCustomValues = [...customValues];
                                            newCustomValues[index] = e.target.value;
                                            setCustomValues(newCustomValues);
                                        }}
                                    />
                                    <TextField
                                        size="small"
                                        label="Remark"
                                        variant="outlined"
                                        sx={{ width: "40%" }}
                                        value={customRemarks[index] || ""}
                                        onChange={(e) => {
                                            const newRemarks = [...customRemarks];
                                            newRemarks[index] = e.target.value;
                                            setCustomRemarks(newRemarks);
                                        }}
                                    />
                                    <IconButton size="small" onClick={() => handleRemoveAgent(index)}>
                                        <Icon>clear</Icon>
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                ))}
                {/* Dialog for adding a custom field */}
                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                    <DialogTitle>Add Custom Field</DialogTitle>
                    <DialogContent>
                        <Box display="flex" gap={1} alignItems={"center"}>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Field Name"
                                fullWidth
                                value={newFieldLabel}
                                onChange={(e) => setNewFieldLabel(e.target.value)}
                            />
                            <Select
                                value={newFieldType}
                                onChange={(e: any) => setNewFieldType(e.target.value)}
                            >
                                <MenuItem value="string">Text</MenuItem>
                                <MenuItem value="number">Number</MenuItem>
                            </Select>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleAddCustomField} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <Box width="100%" my={1}>
                <Divider />
            </Box>

            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Typography sx={{ width: "300px" }}>Remarks: </Typography>
                <TextField
                    value={remark}
                    onChange={(e) => {
                        setRemark(e.target.value);
                    }}
                    multiline
                    rows={4}
                    fullWidth
                />
            </Box>
            <Box width="100%" my={2}>
                <Divider />
            </Box>
            <Box my={1}>
                {currentCase?.id ? (
                    <Button
                        variant="contained"
                        fullWidth
                        color="success"
                        onClick={handleSaveCase}
                        disabled={!isAdminOrEditor()}
                    >
                        Update Case
                    </Button>
                ) : (
                    <Button variant="contained" fullWidth color="success" onClick={handleSaveCase}>
                        Add Case
                    </Button>
                )}
            </Box>
            {isMobile ? <br /> : null}
            <Dialog
                open={shareDialogOpen}
                onClose={() => setShareDialogOpen(false)}
                maxWidth="sm"
                fullWidth
                style={{ minHeight: "500px", minWidth: "500px" }}
            >
                <DialogTitle sx={{ fontSize: "2rem" }}>Share Case</DialogTitle>
                <DialogContent>
                    <Button variant="contained" onClick={handleAddViewer} color="primary">
                        Add People
                    </Button>

                    <Box mt={2}>
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
                        >
                            People with access
                        </Typography>
                    </Box>

                    <List>
                        {sharedUsers.map((user) => (
                            <ListItem key={user.id}>
                                <ListItemText primary={user.name} secondary={user.id} />

                                <FormControl style={{ minWidth: 100 }}>
                                    <Select
                                        value={user.role}
                                        onChange={(e) =>
                                            handleRoleChange(user.id, e.target.value as "Viewer" | "Editor")
                                        }
                                    >
                                        <MenuItem value="Viewer">Viewer</MenuItem>
                                        <MenuItem value="Editor">Editor</MenuItem>
                                    </Select>
                                </FormControl>
                                <IconButton onClick={() => handleRemoveUser(user.id)} aria-label="remove">
                                    <Icon>clear</Icon>
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setShareDialogOpen(false)} color="primary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
