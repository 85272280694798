import { useTheme } from "@emotion/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SendIcon from "@mui/icons-material/Send";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Icon,
    IconButton,
    Input,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    TextField,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import EmojiPicker from "emoji-picker-react";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where
} from "firebase/firestore";
import { getMetadata, ref } from "firebase/storage";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import ChatMessageComponent from "../../components/ChatInbox/ChatMessageComponent";
import ImageIndicator from "../../components/ChatInbox/ImageIndicator";
import AddItemsMenu from "../../components/Menus/AddItemsMenu";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { StateContext } from "../../context/StateContext";
import { db, storage } from "../../firebase/firebase-utils";
import removeSpecialChars from "../../functions/common-functions/removeSpecialChars";
import useColors from "../../hooks/useColors";
import useWindowSize from "../../hooks/useWindowSize";
import convertDate from "../../utils-functions/convertDate";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { sendAttachments, sendMessage } from "../WhatsappWebPage/sendMessageToNames";
import AccountsDialog from "./AccountsDialog";
import { ChatRoomComponent } from "./ChatRoomComponent";
import SortDialog from "./FilterDialog";
import MenuComponent from "./MenuComponent";
import ProfileCard from "./ProfileCard";
import QuotedMessageComponent from "./QuotedMessageComponent";
import WhatsappConnectionComponent from "./WhatsappConnectionComponent";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";

export default function ChatInboxPage() {
    const [clientId, setClientId] = useState(null);
    const [chatRooms, setChatRooms] = useState([]);
    const [currentChatRoom, setCurrentChatRoom] = useState(null);
    const [messages, setMessages] = useState([]);
    const [selectChatRoom, setSelectChatRoom] = useState(true);
    const [apiAddress, setApiAddress] = useState("");
    const [whatsappSession, setWhatsappSession] = useState("");
    const [whatsappPhone, setWhatsappPhone] = useState("");
    const [secret, setSecret] = useState("");
    const [message, setMessage] = useState("");
    const [chatRoomsLimit, setChatRoomsLimit] = useState(20);
    const [messagesLimit, setMessagesLimit] = useState(20);
    const [sort, setSort] = useState("date"); // Add sort state here
    const [openAccount, setOpenAccount] = useState(false);
    const [whatsappUser, setWhatsappUser] = useState("");
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [openEmoji, setOpenEmoji] = useState(false);
    const [cursorPos, setCursorPos] = useState(null);
    const [pastedImage, setPastedImage] = useState(null);
    const [scheduledMessages, setScheduledMessages] = useState([]);
    const [openForwardMsg, setOpenForwardMsg] = useState(false);
    const [forwardMsgObj, setForwardMsgObj] = useState(null);
    const [openContactManagement, setOpenContactManagement] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const { user } = useContext(AuthContext);
    const { state } = useContext(StateContext);

    const inputRef = useRef(null);
    const cursorPosRef = useRef(null);
    const profileCardRef = useRef(null);

    const [openQuickReply, setOpenQuickReply] = useState(false);
    const [quickReplyList, setQuickReplyList] = useState([]);
    const [showProfileCard, setShowProfileCard] = useState(false); // Add state to control ProfileCard visibility

    const [quickReplyTag, setQuickReplyTag] = useState("");
    const [quickReplyText, setQuickReplyText] = useState("");

    const [showTemplate, setShowTemplate] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [editingQuickReply, setEditingQuickReply] = useState(null); // Track the current quick reply being edited
    const [selectedQuickReply, setSelectedQuickReply] = useState(null); // Track the currently selected quick reply
    const [anchorElEmoji, setAnchorElEmoji] = useState(null);
    const [anchorElQuickReply, setAnchorElQuickReply] = useState(null);
    const [replyMessage, setReplyMessage] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [searchQuickReply, setSearchQuickReply] = useState("");
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [recentContacts, setRecentContacts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [rows, setRows] = useState([]);
    const theme = useTheme();
    const [addName, setAddName] = useState("");
    const [addContactNo, setAddContactNo] = useState("");
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const [scheduledDateTime, setScheduledDateTime] = useState(dayjs());
    const colorMode = theme.palette.mode;

    useEffect(() => {
        if (!user) return;
        const collectionRef = collection(db, "whatsappQuickReply");

        // Query for user-created quick replies
        const userQuery = query(collectionRef, where("createdBy", "==", user.id));

        // Query for template quick replies
        const templateQuery = query(collectionRef, where("type", "==", "template"));

        // Function to handle query snapshot and set quick reply list
        const handleSnapshot = async (querySnapshot) => {
            let userQuickReplyList = mapSnapshot(querySnapshot);
            const templateSnapshot = await getDocs(templateQuery);
            let templateQuickReplyList = mapSnapshot(templateSnapshot);

            const combinedList = [...userQuickReplyList, ...templateQuickReplyList];
            combinedList?.sort((a, b) => b.date - a.date); // Sort by newest date first
            setQuickReplyList(combinedList);
        };

        // Subscribe to user query and fetch template query
        const unsubscribeUser = onSnapshot(userQuery, handleSnapshot);

        return () => {
            unsubscribeUser();
        }; // Cleanup subscription on unmount
    }, [user]);

    useEffect(() => {
        if (inputRef.current && cursorPosRef.current !== null) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(cursorPosRef.current, cursorPosRef.current);
        }
    }, [message]);

    useEffect(() => {
        if (cursorPosRef.current !== null) {
            setCursorPos(cursorPosRef.current);
            cursorPosRef.current = null;
        }
    }, [message]);

    useEffect(() => {
        if (!user) return;
        const clientId = removeSpecialChars(user.email);
        setClientId(clientId);
        setWhatsappUser(user);
    }, [user]);

    // Load ChatRooms
    useEffect(() => {
        if (!clientId) return;

        const collectionRef = collection(db, "whatsappMessages");
        const q = query(
            collectionRef,
            where("clientId", "==", clientId),
            limit(chatRoomsLimit),
            orderBy(sort, "desc")
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const chatRooms = mapSnapshot(querySnapshot);
            setChatRooms(chatRooms);
        });
        return () => unsubscribe(); // Cleanup subscription on unmount
    }, [clientId, chatRoomsLimit, sort]); // Add chatRoomsLimit as a dependency

    //load recent contacts
    useEffect(() => {
        if (!clientId) return;
        if (chatRooms.length > 0) {
            const updatedContacts = chatRooms.map((chatRoom) => {
                const contactNo = chatRoom.chatRoomId.split("-")[1];
                let contactName = chatRoom.name || chatRoom.pushname || contactNo;
                return { contactName, contactNo, profilePicUrl: chatRoom.profilePicUrl };
            });
            console.log(updatedContacts);
            setRecentContacts(updatedContacts);
        } else {
            console.log("chatRooms length 0");
        }
    }, [clientId, chatRooms]);

    // Load Messages
    useEffect(() => {
        if (!currentChatRoom) return;

        const collectionRef = collection(db, "whatsappMessages", currentChatRoom.id, "messages");
        const collectionRef2 = collection(db, "scheduledTasks");

        const q = query(collectionRef, limit(messagesLimit), orderBy("date", "desc"));

        const q2 = query(
            collectionRef2,
            where("chatRoomId", "==", currentChatRoom.id),
            orderBy("date", "desc"),
            where("status", "==", "scheduled")
        );

        let unsubscribe2 = () => {}; // Initialize with a no-op function

        const unsubscribe1 = onSnapshot(q, (querySnapshot) => {
            unsubscribe2 = onSnapshot(q2, (querySnapshot2) => {
                const messages = mapSnapshot(querySnapshot);
                const scheduledMessages = mapSnapshot(querySnapshot2);
                setScheduledMessages(scheduledMessages);
                scheduledMessages.forEach((message) =>
                    messages.unshift({
                        ...message,
                        date: convertDate(message.scheduledTime || new Date()),
                        body: message.message,
                        type: "chat",
                        scheduled: true,
                    })
                );

                setMessages(messages);
            });
        });

        // Return a cleanup function that calls both unsubscribe functions
        return () => {
            unsubscribe1();
            unsubscribe2();
        };
        //
    }, [currentChatRoom, messagesLimit]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    //to update the currentChatRoom if user change the name in chatRooms
    useEffect(() => {
        if (!currentChatRoom) return;

        const updatedChatRoom = chatRooms.find((room) => room.id === currentChatRoom.id);
        if (updatedChatRoom && updatedChatRoom.name !== currentChatRoom.name) {
            setCurrentChatRoom(updatedChatRoom);
        }
    }, [chatRooms]);

    // load contacts in contact management
    useEffect(() => {
        const collectionRef = collection(db, "contacts");
        const q = query(collectionRef, where("admins", "array-contains", clientId));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const contactsList = mapSnapshot(snapshot);
            setRows(
                contactsList?.map((contact, index) => {
                    return {
                        ...contact,
                        no: index + 1,
                        id: contact.id,
                    };
                })
            );
        });

        return () => {
            unsubscribe();
        };
    }, [clientId]);

     const handleOpenScheduleMenu = () => {
         console.log("openScheduleDialog:");
         setOpenScheduleDialog(true);
     };

     const handleCloseScheduleDialog = () => {
         setOpenScheduleDialog(false);
     };

    const handleToggle = (value) => () => {
        setSelectedContacts(value);
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
        filterQuickReplies(event.target.value);
    };

    const handleClearSearchText = () => {
        setSearchText("");
        filterQuickReplies("");
    };

    const filterQuickReplies = (text) => {
        // Implement your filtering logic here based on the text
        setSearchQuickReply(
            quickReplyList.filter((item) => item.tag.toLowerCase()?.includes(text.toLowerCase()))
        );
    };

    const handleShowProfileCard = async () => {
        setShowProfileCard(true);
    };

    const handleClickOutside = (event) => {
        if (profileCardRef.current && !profileCardRef.current.contains(event.target)) {
            setShowProfileCard(false);
        }
    };

    const handleRemoveReplyMessage = () => {
        setReplyMessage(null);
    };

    const handleShowTemplateChange = (event) => {
        setShowTemplate(event.target.checked);
        handleClearQuickReply();
    };

    const filteredQuickReplyList = showTemplate
        ? quickReplyList
        : quickReplyList.filter((item) => item.type !== "template");

    const handleClickEmoji = (event) => {
        setAnchorElEmoji(event.currentTarget);
        setOpenEmoji(true);
    };

    const handleCloseEmoji = () => {
        setAnchorElEmoji(null);
        setOpenEmoji(false);
    };

    const handleScroll = (e) => {
        // Check if the user is within 10 pixels of the bottom
        const nearBottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;

        if (nearBottom) {
            setChatRoomsLimit((prevLimit) => prevLimit + 20);
        }
    };

    const handleUpdateReadCounter = (chatRoom) => {
        if (!chatRoom?.id) return;

        if (state?.authUser?.email === "keatwei.chong@gmail.com") return;

        const docRef = doc(db, "whatsappMessages", chatRoom.id);
        updateDoc(docRef, {
            unread: 0,
            unreadAI: 0,
        });
    };

    const handleMessagesScroll = (e) => {
        // Check if the user is within 10 pixels of the top
        const nearTop = e.target.scrollHeight - e.target.clientHeight + e.target.scrollTop < 10;
        const nearBottom = e.target.scrollTop < 10;

        if (nearTop) {
            setMessagesLimit((prevLimit) => prevLimit + 20);
        }

        if (nearBottom) {
            handleUpdateReadCounter();
        }
    };

    const handleClickChatRoom = (chatRoom) => {
        console.log("Chat Room : ", chatRoom);
        handleUpdateReadCounter(chatRoom);

        if (chatRoom !== currentChatRoom) {
            setMessagesLimit(20);
            setCurrentChatRoom(chatRoom);
            setSelectChatRoom(false);
        }
    };

    const windowSize = useWindowSize();

    const boxWidth = useMemo(() => {
        if (windowSize.width < 600) {
            // Mobile View
            if (selectChatRoom) {
                const leftWidth = "100%";
                const rightWidth = 0;
                return { leftWidth, rightWidth };
            } else {
                const leftWidth = 0;
                const rightWidth = "100%";
                return { leftWidth, rightWidth };
            }
        } else {
            // Desktop View
            const leftWidth = "30%";
            const rightWidth = "70%";
            return { leftWidth, rightWidth };
        }
    }, [windowSize, selectChatRoom]);

    const colors = useColors();

    const handleSendMessage = async () => {
        if (!message && !image && !pastedImage) {
            toast.error("No message or image");
            return;
        }
        if (!apiAddress) {
            toast.error("API Not ready");
            return;
        }

        if (!whatsappSession) {
            toast.error("Whatsapp not connected");
            return;
        }

        let number = currentChatRoom.id.split("-")[1];
        const myNumber = currentChatRoom.id.split("-")[0];
        const from = currentChatRoom.clientId;

        // It is a group message
        if (currentChatRoom.isGroup) {
            if (currentChatRoom?.remoteJid) {
                number = currentChatRoom.remoteJid;
            } else {
                if (currentChatRoom?.from === myNumber) {
                    number = checkGroupId(currentChatRoom?.to);
                } else {
                    number = checkGroupId(currentChatRoom?.from);
                }
            }
        }

        setLoading(true);
        if (image || pastedImage) {
            const imgToSend = image || pastedImage;

            console.log("Sending image . . .");
            console.log("user.displayName:", user.displayName);

            let sender = user.displayName;

            const response = await sendAttachments(
                number,
                [imgToSend],
                from,
                message,
                apiAddress,
                secret,
                sender
            );

            console.log(response);

            setImage(null);
            setPastedImage(null);
            setMessage("");
            setLoading(false);

            return;
            //
        }

        const response = await sendMessage(number, message, from, apiAddress, secret, user, {
            ...replyMessage,
        });

        setLoading(false);
        setMessage("");
        setReplyMessage(null);
        setImage(null);
        console.log(response);
        //
    };

    const handleClickAccount = () => {
        setOpenAccount(true);
    };

    const displayChatRooms = useMemo(() => {
        if (!Array.isArray(chatRooms)) return [];
        if (!search) return chatRooms;
        console.log("Searching . . .");
        console.log(chatRooms);

        const displayChatRooms = chatRooms.filter((chatRoom) => {
            console.log(chatRoom);
            const name = chatRoom?.name?.toLowerCase()||'';
            const searchValue = search?.toLowerCase()||'';

            let display = false;
            if (name?.includes(searchValue)) display = true;
            if (chatRoom?.id?.includes(searchValue)) display = true;
            return display;
        });

        return displayChatRooms;
    }, [chatRooms, search]);

    const handleClickQuickReply = (event) => {
        setAnchorElQuickReply(event);
        setOpenQuickReply(true);
    };

    const handleCloseQuickReply = () => {
        setAnchorElQuickReply(null);
        setOpenQuickReply(false);
    };

    const handleSelectEmoji = (e) => {
        const input = inputRef.current;
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;
        const emoji = `${e.emoji}`;
        console.log(emoji);
        setMessage((prevMessage) => prevMessage.slice(0, startPos) + emoji + prevMessage.slice(endPos));
        const newCursorPosition = startPos + emoji.length;
        cursorPosRef.current = newCursorPosition; // Save the new cursor position to the ref
    };

    const handlePaste = (event) => {
        const items = event.clipboardData.items;
        for (const item of items) {
            if (item.type.indexOf("image") !== -1) {
                const blob = item.getAsFile();
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImage(e.target.result);
                };
                reader.readAsDataURL(blob);
            }
        }
    };

    const con = useConfirmation();

    const handleSetQuickReply = async () => {
        if (quickReplyTag === "" || quickReplyText === "") return;
        const response = await con("Are you sure you want to save this quick reply?");
        if (!response) return;

        try {
            if (editingQuickReply) {
                const docRef = doc(db, "whatsappQuickReply", editingQuickReply.id);

                // Update the existing document
                await updateDoc(docRef, {
                    tag: quickReplyTag,
                    desc: quickReplyText,
                });
            } else {
                // Create a new document if it does not exist
                await addDoc(collection(db, "whatsappQuickReply"), {
                    tag: quickReplyTag,
                    desc: quickReplyText,
                    createdBy: user.id,
                    date: new Date(),
                    type: "user",
                });
            }
            setQuickReplyTag("");
            setQuickReplyText("");
            setEditingQuickReply(null); // Reset editing state
            setSelectedQuickReply(null); // Reset selected state
            setRefresh(!refresh);
            toast.success("Quick Reply Set Successfully");
        } catch (error) {
            console.log("Set Quick Reply Error : ", error);
        }
    };

    const handleChangeQuickReplyTag = (e) => {
        setQuickReplyTag(e.target.value);
    };

    const handleChangeQuickReplyText = (e) => {
        setQuickReplyText(e.target.value);
    };

    const handleClickQuickReplyTag = (quickReply) => {
        setQuickReplyTag(quickReply.tag);
        setQuickReplyText(quickReply.desc);
        setEditingQuickReply(quickReply); // Set the current quick reply being edited
        setSelectedQuickReply(quickReply); // Set the selected quick reply
    };

    const handleDeleteQuickReply = async (quickReply) => {
        const response = await con("Are you sure you want to delete this quick reply?");
        if (!response) return;

        try {
            const docRef = doc(db, "whatsappQuickReply", quickReply.id);
            await deleteDoc(docRef);
            toast.success("Quick Reply Deleted Successfully");
        } catch (error) {
            console.log("Delete Quick Reply Error : ", error);
            toast.error("Failed to Delete Quick Reply");
        }
    };

    const handleClearQuickReply = () => {
        setSelectedQuickReply(null);
        setQuickReplyTag("");
        setQuickReplyText("");
        setEditingQuickReply(null);
    };

    const handleUseQuickReply = () => {
        const input = inputRef.current;
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;
        const textToInsert = quickReplyText;

        setMessage(
            (prevMessage) => prevMessage.slice(0, startPos) + textToInsert + prevMessage.slice(endPos)
        );

        const newCursorPosition = startPos + textToInsert.length;
        cursorPosRef.current = newCursorPosition; // Save the new cursor position to the ref

        handleCloseQuickReply();
    };

    const handleOpenForwardMsg = (msgObj) => {
        setForwardMsgObj(msgObj);
        setOpenForwardMsg(true);
    };

    const handleCloseForwardMsg = () => {
        setSelectedContacts([]);
        setOpenForwardMsg(false);
    };

    const retrieveFileMetadata = async (path) => {
        let mimeType = "";
        let size = 0;
        let name = "";
        try {
            // Assuming you have the file path
            console.log(path);
            const fileRef = ref(storage, path);
            console.log("1");
            // Get the metadata
            const metadata = await getMetadata(fileRef);
            mimeType = metadata.contentType;
            size = metadata.size;
            name = metadata.name;

            console.log("MIME Type:", mimeType);
            console.log("Size:", size, "bytes");
            console.log("Name:", name);
            return { name, size, mimeType };
        } catch (error) {
            console.error("Error getting file metadata:", error);
            return null;
        }
    };

    const handleForwardMsg = async () => {
        console.log(forwardMsgObj);
        try {
            if (!apiAddress) {
                toast.error("API Not ready");
                return;
            }

            if (!whatsappSession) {
                toast.error("Whatsapp not connected");
                return;
            }

            let number = currentChatRoom.id.split("-")[1];
            const myNumber = currentChatRoom.id.split("-")[0];
            const from = currentChatRoom.clientId;

            let response = "";
            if (forwardMsgObj.type === "chat" && !forwardMsgObj?.filePath) {
                selectedContacts.length > 0
                    ? selectedContacts.forEach(async (contact) => {
                          number = contact;
                          response = await sendMessage(
                              number,
                              forwardMsgObj.body,
                              from,
                              apiAddress,
                              secret,
                              user,
                              {
                                  ...replyMessage,
                              }
                          );
                      })
                    : "";
            } else {
                let file;
                // check file type from storage here
                const { name, mimeType, size } = await retrieveFileMetadata(forwardMsgObj.filePath);
                file = [
                    {
                        downloadURL: forwardMsgObj.downloadUrl,
                        name,
                        type: mimeType,
                        size,
                    },
                ];

                console.log("Metadata file to sent:", file);
                selectedContacts.length > 0
                    ? selectedContacts.forEach(async (contact) => {
                          console.log("contact:", contact);
                          number = contact;
                          response = await sendAttachments(
                              number,
                              file,
                              from,
                              forwardMsgObj.message || forwardMsgObj.caption,
                              apiAddress,
                              secret,
                              myNumber
                          );
                      })
                    : "";
            }
            setLoading(false);
            setMessage("");
            setReplyMessage(null);
            setForwardMsgObj(null);
            setSelectedContacts([]);
            setOpenForwardMsg(false);

            console.log(response);
        } catch (err) {
            console.log(err);
        }
    };

    const handleToggleContactList = (value) => () => {
        const currentIndex = selectedContacts.indexOf(value);
        const newChecked = [...selectedContacts];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedContacts(newChecked);
    };

    const clearSearch = () => {
        setSearchQuery("");
        setFilteredContacts([]);
    };

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        if (query) {
            const lowerQuery = query.toLowerCase();
            const result = contacts.filter(
                (contact) =>
                    contact.contactName.toLowerCase()?.includes(lowerQuery) ||
                    contact.contactNo?.includes(query)
            );
            setFilteredContacts(result);
        } else {
            setFilteredContacts([]);
        }
    };

    const handleCloseContactManagement = () => {
        setOpenContactManagement(false);
    };

    const handleDeleteContacts = async () => {
        const response = await con("Delete contact", "Are you sure you want to delete these contact(s)?");
        if (!response) return;

        const collectionRef = collection(db, "contacts");
        rowSelectionModel.forEach(async (id) => {
            await deleteDoc(doc(collectionRef, id));
        });
        setRefresh(!refresh);
    };

    const displayContacts = searchQuery ? filteredContacts : contacts;

    const formatDate = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
        return date.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric", // Adjust the date format as needed
        });
    };

    const columns = [
        { field: "no", headerName: "No", width: 90 },
        { field: "contactName", headerName: "Name", width: 150 },
        { field: "contactNo", headerName: "Phone", width: 250 },
        { field: "type", headerName: "Type", width: 150 },
    ];

    const handleAddContact = async () => {
        if (!addName || !addContactNo) {
            toast.error("Please fill in both name and contact number.");
            return;
        }

        try {
            // Check for existing contact with the same number
            const contactsRef = collection(db, "contacts");
            const q = query(contactsRef, where("contactNo", "==", addContactNo));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                toast.error("A contact with this number already exists.");

                return;
            }

            // Add new contact
            await addDoc(contactsRef, {
                contactName: addName,
                contactNo: addContactNo,
                type: "added from DJC",
                admins: [clientId],
            });

            toast.success("Contact added successfully!");

            // Reset fields after adding
            setAddName("");
            setAddContactNo("");
        } catch (error) {
            console.error("Error adding contact: ", error);
            toast.error("Error adding contact. Please try again.");
        }
    };

    const handleAddClear = () => {
        setAddName("");
        setAddContactNo("");
    };

    const handleSendScheduleMessage = async () => {
        setLoading(true);
        const scheduleMessageData = {
            chatRoomId: currentChatRoom.id,
            files: image ? [image] : [],
            accessToken: '',
            messageText: message,
            msgType: "",
            object: "whatsapp",
            scheduled: true,
            scheduledTime: new Date(scheduledDateTime),
            email: user.email,
            clientId: user.clientId,
        };

        if (image?.type?.includes("application")) {
            scheduleMessageData.msgType = "file";
        }

        if (image?.type?.includes("image")) {
            scheduleMessageData.msgType = "image";
        }

        if (message) {
            scheduleMessageData.msgType = "text";
        }
        const data = JSON.stringify(scheduleMessageData);

        console.log("scheduleMessageData: stringify", data);

        const config = {
            method: "post",
            url: "https://sendscheduledmessage-pc6xwmvyrq-as.a.run.app",
            headers: {
                "Content-Type": "application/json",
            },
            data,
        };

        try {
            const response = await axios.request(config);
            setLoading(false);
            setMessage("");
            setImage(null);
            return response.data;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const handleScheduleSend = async () => {

        const confirmed = await con(
            "Schedule Message",
            `Are you sure you want to schedule this message for ${scheduledDateTime.toLocaleString()}?`
        );
        if (confirmed) {
            // TODO: Implement the logic to schedule the message
            await handleSendScheduleMessage();
            toast.success("Message scheduled successfully");
            handleCloseScheduleDialog();
        }
    };

    return (
        <>
            <Box display="flex" width="100%" height={"calc(100vh - 55px)"}>
                {/* Left Panel */}
                <Box
                    display={!boxWidth.leftWidth ? "none" : "flex"}
                    width={boxWidth.leftWidth}
                    flexDirection={"column"}
                    height={"100%"}
                >
                    <Box
                        p={"8px"}
                        width={"100%"}
                        display={"flex"}
                        alignItems={"center"}
                        gap="8px"
                        justifyContent={"space-between"}
                        sx={{ background: colors.topBar, borderRight: `1px solid ${colors.topBarLine}` }}
                    >
                        <Avatar src="" />
                        <Box display="flex" flexDirection={"column"}>
                            <Typography>{clientId}</Typography>
                            <WhatsappConnectionComponent
                                whatsappUser={whatsappUser}
                                whatsappSession={whatsappSession}
                                setApiAddress={setApiAddress}
                                setWhatsappSession={setWhatsappSession}
                                setWhatsappPhone={setWhatsappPhone}
                                setSecret={setSecret}
                            />
                        </Box>

                        <AccountsDialog
                            open={openAccount}
                            setOpen={setOpenAccount}
                            setClientId={setClientId}
                            setWhatsappUser={setWhatsappUser}
                        />
                    </Box>
                    <Box
                        display="flex"
                        width={"100%"}
                        p="8px"
                        gap="8px"
                        sx={{ borderBottom: `1px solid ${colors.topBarLine}` }}
                    >
                        <Input
                            disableUnderline
                            fullWidth
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            sx={{
                                background: colors.searchBox,
                                borderRadius: "6px",
                                height: "35px",
                                padding: "0 8px 0 8px",
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment
                                    position="start"
                                    sx={{ display: search ? "inherit" : "none" }}
                                >
                                    <Icon onClick={() => setSearch("")}>clear</Icon>
                                </InputAdornment>
                            }
                        />
                        <SortDialog sort={sort} setSort={setSort} />
                    </Box>
                    <Box
                        flexGrow={1}
                        display="flex"
                        flexDirection={"column"}
                        width="100%"
                        sx={{ overflowY: "auto" }}
                        onScroll={handleScroll} // Add the scroll handler here
                    >
                        {displayChatRooms.map((chatRoom) => (
                            <Box key={chatRoom.id} width={"100%"}>
                                <ChatRoomComponent chatRoom={chatRoom} handleClick={handleClickChatRoom} />
                            </Box>
                        ))}
                    </Box>
                </Box>

                {/* Right Panel */}
                <Box
                    width={boxWidth.rightWidth}
                    display={!boxWidth.rightWidth ? "none" : "flex"}
                    flexDirection={"column"}
                    alignContent={"center"}
                    height={"100%"}
                >
                    <Box
                        display={"flex"}
                        width={"100%"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        p={"8px"}
                        alignItems={"center"}
                        sx={{ background: colors.topBar }}
                    >
                        <Box
                            display="flex"
                            alignItems={"center"}
                            gap="8px"
                            onClick={handleShowProfileCard}
                            style={{ cursor: "pointer" }}
                        >
                            {boxWidth.leftWidth === 0 && (
                                <IconButton onClick={() => setSelectChatRoom(true)}>
                                    <ArrowBackIcon />
                                </IconButton>
                            )}
                            <Avatar
                                src={currentChatRoom?.profilePicUrl || ""}
                                // This line ensures the cursor changes to a pointer on hover
                            />
                            <Box display="flex" flexDirection="column">
                                <Typography variant="body1">{currentChatRoom?.name || ""}</Typography>
                                {!currentChatRoom?.isGroup && (
                                    <Typography variant="body2">
                                        {currentChatRoom?.chatRoomId.split("-")[1] || ""}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box display="flex" alignItems={"center"} gap="8px">
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                            <MenuComponent
                                // menuItems={["Contact Info", "Select Messages"]}
                                menuItems={["Contact Info"]}
                                onMenuItemClick={(menuItem) => {
                                    if (menuItem === "Contact Info") {
                                        handleShowProfileCard();
                                    }
                                }}
                            />
                        </Box>
                    </Box>

                    <Box
                        flex={1}
                        display="flex"
                        flexDirection={"column-reverse"}
                        width="100%"
                        height="100%"
                        onScroll={handleMessagesScroll} // Add the scroll handler here
                        sx={{
                            backgroundImage: `url(${colors.whatsappBackground})`,
                            backgroundSize: "cover", // to cover the entire Box area
                            backgroundPosition: "center",
                            overflowY: "auto",
                        }}
                    >
                        {
                            // messages
                            messages.map((message) => (
                                <ChatMessageComponent
                                    key={message.id}
                                    message={message}
                                    clientName={currentChatRoom.name}
                                    setReplyMessage={setReplyMessage}
                                    handleOpenForwardMsg={handleOpenForwardMsg}
                                />
                            ))
                            //
                        }
                    </Box>

                    {showProfileCard && (
                        <div
                            ref={profileCardRef}
                            style={{ position: "absolute", top: "10%", right: "20%", zIndex: 1000 }}
                        >
                            <ProfileCard chatRoom={currentChatRoom} />
                        </div>
                    )}
                    <Box
                        width={"100%"}
                        display="flex"
                        alignItems={"center"}
                        flexDirection={"column"}
                        sx={{ background: colors.topBar }}
                    >
                        {replyMessage && (
                            <Box
                                p="4px"
                                width={"100%"} // Make sure the replyMessage occupies the full width
                                display="flex"
                                alignItems="center"
                            >
                                <QuotedMessageComponent
                                    quotedMessage={replyMessage.quotedMessage}
                                    sender={replyMessage.sender}
                                    onClose={handleRemoveReplyMessage}
                                />
                            </Box>
                        )}
                        <Box
                            p="4px"
                            width={"100%"}
                            display={currentChatRoom ? "flex" : "none"}
                            alignItems={"center"}
                            flexDirection={"row"}
                        >
                            <IconButton onClick={handleClickEmoji}>
                                <InsertEmoticonIcon fontSize="large" />
                            </IconButton>
                            <AddItemsMenu
                                attachment={image}
                                setAttachment={setImage}
                                setOpenQuickReply={handleClickQuickReply}
                            />
                            {image && (
                                <Box display="flex" alignItems="center">
                                    <ImageIndicator
                                        image={image}
                                        setImage={image ? setImage : setPastedImage}
                                    />
                                </Box>
                            )}

                            <Input
                                fullWidth
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && !e.shiftKey) {
                                        e.preventDefault();
                                        handleSendMessage();
                                    }
                                }}
                                onPaste={handlePaste}
                                disableUnderline
                                multiline
                                maxRows={6}
                                sx={{
                                    background: "white",
                                    borderRadius: "6px",
                                    padding: "0px 8px 0 8px",
                                    minHeight: "40px",
                                    backgroundColor: colors.textInput,
                                }}
                                inputRef={inputRef}
                            />
                            <IconButton
                                disabled={(!currentChatRoom && !image) || loading || !whatsappSession}
                                onClick={handleSendMessage}
                            >
                                {loading ? <CircularProgress size={24} /> : <SendIcon fontSize="large" />}
                            </IconButton>
                            <IconButton
                                onClick={handleOpenScheduleMenu}
                                disabled={message.trim() === "" && !image}
                            >
                                <ScheduleIcon fontSize="large" color="#54656f" />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Menu anchorEl={anchorElEmoji} open={Boolean(anchorElEmoji)} onClose={handleCloseEmoji}>
                <EmojiPicker
                    width={"100%"}
                    height={"400px"}
                    onEmojiClick={handleSelectEmoji}
                    theme={colorMode}
                    autoFocusSearch={false}
                    searchDisabled={true}
                />
            </Menu>
            <Menu
                anchorEl={anchorElQuickReply}
                open={Boolean(anchorElQuickReply)}
                onClose={handleCloseQuickReply}
                sx={{
                    "& .MuiPaper-root": {
                        width: "80%",
                        maxWidth: "350px",
                    },
                }}
            >
                <Box sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "8px 0",
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox checked={showTemplate} onChange={handleShowTemplateChange} />}
                            label="Template"
                        />
                        <TextField
                            label="Search"
                            value={searchText}
                            onChange={handleSearchTextChange}
                            InputProps={{
                                endAdornment: searchText ? (
                                    <IconButton onClick={handleClearSearchText}>
                                        <SearchOffIcon />
                                    </IconButton>
                                ) : null,
                            }}
                            sx={{ marginLeft: 2 }}
                        />
                    </Box>
                    <Box
                        sx={{
                            maxHeight: "150px", // Adjust the height as needed
                            overflowY: "auto",
                            display: "flex",
                            flexWrap: "wrap",
                            // gap: 1, // Adjust the gap as needed
                            padding: 0.5, // Add padding if needed
                        }}
                    >
                        {filteredQuickReplyList?.map((item, index) => (
                            <Chip
                                key={index}
                                label={item.tag}
                                color={
                                    selectedQuickReply && selectedQuickReply.id === item.id
                                        ? "success"
                                        : "default"
                                }
                                sx={{ marginBottom: 1, marginLeft: 1 }}
                                onClick={() => handleClickQuickReplyTag(item)}
                                onDelete={() => item.type !== "template" && handleDeleteQuickReply(item)} // Disable delete if type is template
                                deleteIcon={item.type === "template" ? <span /> : undefined} // Hide delete icon if type is template
                            />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "100%",
                            padding: "4px",
                        }}
                    >
                        <TextField
                            label="Quick Reply Tag"
                            fullWidth
                            sx={{ marginBottom: "10px" }}
                            value={quickReplyTag}
                            onChange={handleChangeQuickReplyTag}
                            disabled={selectedQuickReply && selectedQuickReply.type === "template"} // Disable if type is template
                        />
                        <TextField
                            id="quick-reply-text"
                            label="Quick Reply Text"
                            multiline
                            fullWidth
                            minRows={4}
                            maxRows={4}
                            value={quickReplyText}
                            onChange={handleChangeQuickReplyText}
                            sx={{ marginBottom: "10px" }}
                            disabled={selectedQuickReply && selectedQuickReply.type === "template"} // Disable if type is template
                        />
                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                            <Button
                                variant="contained"
                                onClick={handleSetQuickReply}
                                sx={{ flex: 1 }}
                                disabled={selectedQuickReply && selectedQuickReply.type === "template"} // Disable if type is template
                            >
                                {editingQuickReply ? "Save" : "Add"}
                            </Button>
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={handleClearQuickReply}
                                sx={{ flex: 1 }}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleUseQuickReply}
                                sx={{ flex: 1 }}
                                disabled={!selectedQuickReply} // Disable if no quick reply is selected
                            >
                                Use This
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Menu>
            <Dialog open={openForwardMsg} onClose={handleCloseForwardMsg} maxWidth="sm" fullWidth>
                <DialogTitle>Forward to...</DialogTitle>
                <DialogContent dividers style={{ maxHeight: "400px", overflow: "auto" }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid xs={12} sm={8}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Search contacts"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={clearSearch}>
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid xs={12} sm={4}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => setOpenContactManagement(true)}
                            >
                                Contact Management
                            </Button>
                        </Grid>
                    </Grid>
                    {!searchQuery && recentContacts.length > 0 && (
                        <>
                            <Divider
                                sx={{
                                    pt: {
                                        xs: 2, // 16px padding-top for extra small screens and up
                                        md: 1, // 0px padding-top for medium screens and up
                                    },
                                }}
                            >
                                Frequently Contacted
                            </Divider>

                            <List>
                                {recentContacts.map((contact, index) => (
                                    <ListItem
                                        key={index}
                                        onClick={handleToggleContactList(contact.contactNo)}
                                    >
                                        <ListItemAvatar>
                                            <Avatar alt={contact.contactName} src={contact.profilePicUrl} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={contact.contactName}
                                            secondary={contact.contactNo}
                                        />
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onChange={handleToggleContactList(contact.contactNo)}
                                                checked={selectedContacts?.includes(contact.contactNo)}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    )}
                    {displayContacts.length > 0 && (
                        <>
                            <Divider
                                sx={{
                                    pt: {
                                        xs: 2, // 16px padding-top for extra small screens and up
                                        md: 1, // 0px padding-top for medium screens and up
                                    },
                                }}
                            >
                                Contacts
                            </Divider>
                            <List>
                                {displayContacts.map((contact, index) => (
                                    <ListItem
                                        key={index}
                                        onClick={handleToggleContactList(contact.contactNo)}
                                    >
                                        <ListItemAvatar>
                                            <Avatar alt={contact.contactName} src={contact.profilePicUrl} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={contact.contactName}
                                            secondary={contact.contactNo}
                                        />
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onChange={handleToggleContactList(contact.contactNo)}
                                                checked={selectedContacts?.includes(contact.contactNo)}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseForwardMsg} color="primary" variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={handleForwardMsg} color="primary" variant="contained">
                        Forward
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openContactManagement}
                onClose={handleCloseContactManagement}
                fullWidth={true} // Ensure it uses the full width available
                maxWidth="xl"
            >
                <DialogTitle>Contacts Management</DialogTitle>
                <Divider>Add Contact</Divider>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        px: { xs: 2, sm: 0 }, // Add horizontal padding on extra small screens
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            marginBottom: 2,
                            maxWidth: "md", // Limit the maximum width
                            width: "100%",
                        }}
                    >
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Name"
                                fullWidth
                                size="small"
                                value={addName}
                                onChange={(e) => setAddName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Contact No"
                                fullWidth
                                size="small"
                                value={addContactNo}
                                onChange={(e) => {
                                    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                                    setAddContactNo(onlyNums);
                                }}
                                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                    justifyContent: { xs: "center", sm: "flex-start" },
                                }}
                            >
                                <Button variant="contained" color="primary" onClick={handleAddContact}>
                                    Add
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleAddClear}>
                                    Clear
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <DialogContent sx={{ marginBottom: 2 }}>
                    <div style={{ height: 400, width: "100%" }}>
                        <DataGrid
                            slots={{
                                toolbar: () => <CustomToolbar onDelete={handleDeleteContacts} />,
                            }}
                            getRowId={(row) => row.id}
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[30]}
                            checkboxSelection
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                        />
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseContactManagement}>Cancel</Button>
                </DialogActions>
            </Dialog>
            {/* Schedule Dialog */}
            <Dialog open={openScheduleDialog} onClose={handleCloseScheduleDialog}>
                <DialogTitle>Schedule Message</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ mt: 2 }}>
                            <DateTimePicker
                                label="Date & Time"
                                value={scheduledDateTime}
                                onChange={(newValue) => setScheduledDateTime(newValue)}
                                format="DD/MM/YYYY HH:mm" // Add this line
                                slotProps={{
                                    textField: { fullWidth: true },
                                    popper: {
                                        placement: "top",
                                        modifiers: [
                                            {
                                                name: "flip",
                                                enabled: false,
                                            },
                                            {
                                                name: "preventOverflow",
                                                enabled: true,
                                                options: {
                                                    altAxis: true,
                                                    altBoundary: true,
                                                    tether: false,
                                                    rootBoundary: "document",
                                                },
                                            },
                                        ],
                                    },
                                }}
                            />
                        </Box>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseScheduleDialog}>Cancel</Button>
                    <Button onClick={handleScheduleSend} variant="contained" color="primary">
                        Schedule Send
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function CustomToolbar({ onDelete, disabled }) {
    return (
        <GridToolbarContainer>
            <GridToolbar />
            <GridToolbarQuickFilter />
            <Box display="flex" alignItems={"center"} pt="4px">
                <Button
                    startIcon={<DeleteIcon />}
                    color="error"
                    onClick={onDelete}
                    // disabled={contactList.length === 0 || disabled}
                    size="small"
                >
                    Delete
                </Button>
            </Box>
        </GridToolbarContainer>
    );
}

const checkGroupId = (id) => {
    if (id?.includes("g.us")) return id;

    if (id.length > 18) {
        const formattedId = id.substring(0, 11) + "-" + id.substring(11) + "@g.us";
        return formattedId;
    }

    return `${id}@g.us`;
};
