import React from "react";
import {
    Card,
    CardContent,
    Avatar,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemText,
    Box,
    Chip,
    TextField,
    IconButton,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { db } from "../../firebase/firebase-utils";
import { AuthContext } from "../../context/AuthContext";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { collection, getDocs, query, where, updateDoc, doc } from "firebase/firestore";
import { toast } from "react-hot-toast";
import FullDialog from "../../components/FullDialog";
import ManageLabels from "../../components/ManageLabels";
import { ListItemButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ProfileCard = ({ chatRoom, lastSeen = "" }) => {
    const { user } = useContext(AuthContext);
    const [tagLabelList, setTagLabelList] = useState({});
    const [isEditingName, setIsEditingName] = useState(false);
    const [editedName, setEditedName] = useState(chatRoom?.pushName || chatRoom?.name || '');
    const [openLabel, setOpenLabel] = useState(false);

    const styles = {
        root: {
            maxWidth: 500,
            margin: "0 auto",
            padding: "8px",
        },
        largeAvatar: {
            width: "102px",
            height: "102px",
            marginBottom: "16px",
        },
        header: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "16px",
        },
        section: {
            marginBottom: "16px",
        },
        muted: {
            display: "flex",
            alignItems: "center",
        },
        nameContainer: {
            display: "flex",
            alignItems: "center",
            gap: "8px",
        },
    };

    if (!chatRoom) return null;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userClientsRef = collection(db, "userClients");
                const qUserClient = query(userClientsRef, where("owner", "==", user.email));

                const leadsRef = collection(db, "leads");
                const qLeads = query(leadsRef, where("ownerEmail", "==", user.email));

                const chatRoomIdSuffix = chatRoom.chatRoomId.split("-")[1];

                const userClientsSnapshot = await getDocs(qUserClient);
                const leadsSnapshot = await getDocs(qLeads);

                const filteredUserClientsTags = userClientsSnapshot.docs
                    .map((doc) => ({ phone: doc.data().phone, tags: doc.data().tags, name: chatRoom.name }))
                    .filter(
                        (doc) =>
                            doc.phone &&
                            doc.phone.indexOf(chatRoomIdSuffix) !== -1 &&
                            doc.tags &&
                            doc.tags.length > 0
                    )
                    .flatMap((doc) =>
                        doc.tags.map((tag) => ({
                            label: tag,
                            color: "green",
                            fontColor: "white",
                            name: doc.name,
                        }))
                    );

                const filteredLeadsLabels = leadsSnapshot.docs
                    .map((doc) => ({
                        phoneNumber: doc.data().phoneNumber,
                        labels: doc.data().labels,
                        name: chatRoom.name,
                    }))
                    .filter(
                        (doc) =>
                            doc.phoneNumber &&
                            doc.phoneNumber.indexOf(chatRoomIdSuffix) !== -1 &&
                            doc.labels &&
                            doc.labels.length > 0
                    )
                    .flatMap((doc) =>
                        doc.labels.map((labelObj) => ({
                            label: labelObj.label,
                            color: labelObj.color,
                            fontColor: labelObj.fontColor,
                            name: doc.name,
                        }))
                    );

                const combinedArray = [...filteredUserClientsTags, ...filteredLeadsLabels];

                const resultObject = combinedArray.reduce((acc, curr) => {
                    if (!acc.name) {
                        acc.name = curr.name;
                        acc.labels = [];
                    }
                    const labelObj = {
                        label: curr.label,
                        color: curr.color,
                        fontColor: curr.fontColor,
                    };
                    if (
                        !acc.labels.some(
                            (label) =>
                                label.label === labelObj.label &&
                                label.color === labelObj.color &&
                                label.fontColor === labelObj.fontColor
                        )
                    ) {
                        acc.labels.push(labelObj);
                    }
                    return acc;
                }, {});

                console.log("resultObject:", resultObject);
                setTagLabelList(resultObject); // Set the resultObject directly
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, [chatRoom, user]);

    const copyPhoneId = () => {
        const phoneId = chatRoom.id.split("-")[1];
        navigator.clipboard.writeText(phoneId);
        toast.success("Phone number copied", { duration: 2000 });
    };

    const handleEditClick = () => {
        setIsEditingName(true);
    };

    const handleSaveClick = async () => {
        if (!editedName.trim()) {
            toast.error("Name cannot be empty.");
            return;
        }
        try {
            const chatRoomRef = doc(db, "whatsappMessages", chatRoom.chatRoomId);
            await updateDoc(chatRoomRef, { name: editedName });
            setIsEditingName(false);
        } catch (error) {
            console.error("Error updating name: ", error);
        }
    };

    return (
        <>
            <Card style={styles.root}>
                <CardContent>
                    <div style={styles.header}>
                        <Avatar
                            alt={chatRoom.name}
                            src={chatRoom.profilePicUrl || ""}
                            style={styles.largeAvatar}
                        />
                        <div style={styles.nameContainer}>
                            {isEditingName ? (
                                <TextField
                                    value={editedName}
                                    onChange={(e) => setEditedName(e.target.value)}
                                    size="small"
                                    autoFocus
                                />
                            ) : (
                                <Typography variant="h6" onClick={handleEditClick}>
                                    {chatRoom.pushName || chatRoom.name}
                                </Typography>
                            )}
                            {isEditingName ? (
                                <IconButton onClick={handleSaveClick} size="small">
                                    <CheckIcon />
                                </IconButton>
                            ) : (
                                <IconButton onClick={handleEditClick} size="small">
                                    <EditIcon />
                                </IconButton>
                            )}
                        </div>
                        <Typography variant="body2" color="textSecondary">
                            {chatRoom.about}
                        </Typography>
                    </div>
                    <Divider />
                    <List>
                        <ListItemButton onClick={copyPhoneId}>
                            <ListItemText
                                primary={
                                    <Box display="flex" alignItems="center">
                                        <span>Phone number</span>
                                        <ContentCopyIcon fontSize="small" sx={{ ml: 1 }} />
                                    </Box>
                                }
                                secondary={chatRoom.formattedNumber || chatRoom.chatRoomId.split("-")[1]}
                            />
                        </ListItemButton>
                        
                        {tagLabelList &&
                            Object.keys(tagLabelList).length > 0 &&
                            tagLabelList.labels &&
                            tagLabelList.labels.length > 0 && (
                                <>
                                    <Divider>Lead Tag/Label</Divider>
                                    <ListItem>
                                        <Box
                                            sx={{
                                                maxHeight: "150px",
                                                overflowY: "auto",
                                                display: "flex",
                                                flexWrap: "wrap",
                                                padding: 0.5,
                                                alignItems: "flex-start",
                                                overflowX: "hidden",
                                                maxWidth: "300px",
                                            }}
                                        >
                                            {tagLabelList.labels?.map((item, index) => (
                                                <Chip
                                                    key={index}
                                                    label={
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                display: "inline-block",
                                                                maxWidth: "150px",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {item?.label || "Empty"}
                                                        </Box>
                                                    }
                                                    // color={item.color || 'primary'}
                                                    sx={{
                                                        marginBottom: 1,
                                                        marginLeft: 1,
                                                        flex: "0 1 auto",
                                                        backgroundColor: item?.color || "red",
                                                    }}
                                                />
                                            ))}
                                            {/* <IconButton
                                                onClick={() => setOpenLabel(true)}
                                                size="small"
                                                sx={{ marginLeft: 1 }}
                                            >
                                                <EditIcon />
                                            </IconButton> */}
                                        </Box>
                                    </ListItem>
                                </>
                            )}
                    </List>
                </CardContent>
            </Card>
            <FullDialog
                open={openLabel}
                handleClose={() => setOpenLabel(false)}
                title={"Labels"}
                Component={<ManageLabels lead={tagLabelList} setLead={() => {}} handleClose={() => {}} />}
            />
        </>
    );
};

export default ProfileCard;
