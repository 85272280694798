import axios from "axios";

const sendInstagramMessage = async ({
    pageId,
    recipientId,   
    fileUrl,
    accessToken,
    messageText,
    msgType,
}: {
    pageId: string;
    recipientId: string;   
    fileUrl?: string | null;
    accessToken: string;
    messageText?: string | null;
    msgType: "text" | "file" | "image" | "video" | "audio";
}) => {
    console.log("pageId: ", pageId);
    console.log("recipientId: ", recipientId);
    console.log("fileUrl: ", fileUrl);
    console.log("accessToken: ", accessToken);
    console.log("messageText: ", messageText);
    console.log("msgType: ", msgType);
    //
    const data = JSON.stringify({
        pageId,
        recipientId,
        fileUrl: fileUrl || undefined,
        accessToken,
        messageText: messageText || undefined,
        msgType,
    });

    const config = {
        method: "post",
        url: "https://sendinstagrammessage-pc6xwmvyrq-as.a.run.app/",
        headers: {
            "Content-Type": "application/json",
        },
        data,
    };

    try {
        const response = await axios.request(config);
        return response.data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export default sendInstagramMessage;
