import { Box, Button, Container, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { addDoc, collection, doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { GridContainer, GridDivider, GridFlexBox, Loading, Title } from "../../themes/themes";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import { Name } from "../../themes/themes";

export default function StripeCheckoutPagePremium15() {
    const { user } = useContext(AuthContext);
    const [docId, setDocId] = React.useState();
    const [url, setUrl] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(0);
    const [stripeId, setStripeId] = React.useState("");
    const [checkoutId, setCheckoutId] = React.useState("");
    const [startProcess, setStartProcess] = React.useState(false);
    const [priceId, setPriceId] = React.useState("");
    const [freeTrial, setFreeTrial] = React.useState(0);
    const [product, setProduct] = React.useState("");
    const [eliteone, setEliteone] = React.useState(false);

    const { params } = useParams();

    useEffect(() => {
        if (params?.includes("free-trial")) {
            setFreeTrial(7);
        }

        if (params?.includes("free-trial-eliteone")) {
            setFreeTrial(30);
            setEliteone(true);
        }
    }, []);

    useEffect(() => {
        if (checkoutId) {
            console.log("Useeffect checkoutId: ", checkoutId);
            const docRef = doc(db, "stripeCheckouts", checkoutId);
            return onSnapshot(docRef, (snap) => {
                const checkout = mapDocSnapshot(snap);
                if (checkout?.url) {
                    setUrl(checkout.url);
                    setLoading(false);
                }
            });
        }
    }, [checkoutId]);

    useEffect(() => {
        console.log(docId);
        if (docId) {
            console.log("docId: ", docId);
            const docRef = doc(db, "customers", user.uid, "checkout_sessions", docId);
            return onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data();
                if (error) {
                    // Show an error to your customer and
                    // inspect your Cloud Function logs in the Firebase console.
                    alert(`An error occured: ${error.message}`);
                    // setLoading(false);
                }
                if (url) {
                    // We have a Stripe Checkout URL, let's redirect.
                    // console.log("url: ", url);
                    // setUrl(url);
                    // setLoading(false);
                }
            });
        }
    }, [docId]);

    useEffect(() => {
        if (user.uid) {
            const docRef = doc(db, "customers", user.uid);
            return onSnapshot(docRef, (snap) => {
                const customer = mapDocSnapshot(snap);
                if (customer?.stripeId) {
                    setStripeId(customer.stripeId);
                    console.log("stripeId: ", customer.stripeId);
                }
            });
        }
    }, [user]);

    useEffect(() => {
        if (url) {
            // if url scroll to bottom smoothly
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [url]);

    const loadStripe = async (priceId) => {
        const products = [{ description: "RM1,495.00 / month", priceId: "price_1PsH36LWoLTwDp2ICP7rTZyy" }];
        const product = products.find((product) => product.priceId === priceId);
        setProduct(product.description);
        setPriceId(priceId);

        // This is used only to create customer in Stripe through Firebase Extension, not the actual checkout
        if (!stripeId) {
            const collectionRef = collection(db, "customers", user.uid, "checkout_sessions");
            setLoading(true);
            const docRef = await addDoc(collectionRef, {
                price: priceId,
                success_url: window.location.origin,
                cancel_url: window.location.origin,
            });

            console.log(user.uid);

            if (docRef.id) {
                console.log(docRef.id);
                setDocId(docRef.id);
                setStartProcess(true);
            } else {
                setLoading(false);
                toast.error("Error creating checkout session");
            }
        } else {
            checkout(priceId);
        }
    };

    useEffect(() => {
        console.log("startProcess: ", startProcess);
        console.log("stripeId: ", stripeId);
        if (!startProcess) return;
        if (!stripeId) return;
        console.log("startProcess2: ", startProcess);

        checkout(priceId);
    }, [stripeId, startProcess]);

    const checkout = async (priceId) => {
        console.log("checkout");
        const collectionRef = collection(db, "stripeCheckouts");
        const trialDays = freeTrial;

        // Change this to allow promotion codes
        const allow_promotion_codes = false;

        const successURL = "https://djcsystem.com";
        const cancelURL = "https://djcsystem.com";
        const customerId = stripeId;
        setLoading(true);

        const response = await addDoc(collectionRef, {
            priceId,
            trialDays,
            successURL,
            cancelURL,
            customerId,
            allow_promotion_codes,
        });
        const checkoutId = response.id;
        console.log("checkoutId: ", checkoutId);
        setCheckoutId(checkoutId);
    };

    const handleChangeTab = (e, value) => {
        setTabValue(value);
    };

    const CheckOut = () => {
        return (
            <>
                <Box width={"100%"}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <Button
                            variant="contained"
                            size="large"
                            sx={{ width: "100%" }}
                            disabled={loading}
                            color="warning"
                        >
                            {freeTrial ? "Start My Free Trial" : "Start my Subscription"}
                        </Button>
                    </a>
                </Box>
                <Box mt={"8px"}>
                    {freeTrial ? (
                        <Typography variant="body2">
                            {freeTrial} days free from today, then just {product} (plus applicable taxes).
                            Cancel through your dashboard or by e-mail (admin@djc.ai). I also agree to the{" "}
                            <a
                                href="https://www.djc.ai/termsandservices"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "underline", color: "inherit" }}
                            >
                                Terms of Service
                            </a>{" "}
                            and{" "}
                            <a
                                href="https://www.djc.ai/privacypolicy"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "underline", color: "inherit" }}
                            >
                                Privacy Policy
                            </a>
                        </Typography>
                    ) : (
                        // No free trial
                        <Typography variant="body2">
                            {product} (plus applicable taxes). Cancel through your dashboard or by e-mail
                            (admin@djc.ai). I also agree to the{" "}
                            <a
                                href="https://www.djc.ai/termsandservices"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "underline", color: "inherit" }}
                            >
                                Terms of Service
                            </a>{" "}
                            and{" "}
                            <a
                                href="https://www.djc.ai/privacypolicy"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "underline", color: "inherit" }}
                            >
                                Privacy Policy
                            </a>
                        </Typography>
                    )}
                </Box>
            </>
        );
    };

    return (
        <Container maxWidth="md">
            <GridContainer>
                <GridFlexBox gap="8px">
                    <Title>Subscription</Title>
                    {Boolean(freeTrial) && <Typography variant="h5">{freeTrial} Days Free Trial </Typography>}
                </GridFlexBox>
                <GridDivider />
                <GridFlexBox gap="14px">
                    <Name fs14>Current Subscription:</Name>
                    <Name fs14>{user.addOn === "whatsappCloud" ? "Whatsapp Cloud" : "None"}</Name>
                </GridFlexBox>
                <GridDivider />
                {Boolean(freeTrial) && (
                    <Paper>
                        <Box m={{ md: "30px", xs: "4px" }} gap={1}>
                            <Typography variant="h4">100% NO - RISK FREE TRIAL</Typography>
                            <Typography variant="h5">✅ Get access to all features</Typography>
                            <Typography variant="h5">✅ Pay NOTHING for the first 7-days</Typography>
                            <Typography variant="h5">✅ Cancel anytime, no questions asked</Typography>
                        </Box>
                    </Paper>
                )}
                <GridDivider />
                <Tabs value={tabValue} onChange={handleChangeTab} variant="fullWidth">
                    <Tab label="Monthly" />
                </Tabs>
                <GridDivider />
                {tabValue === 0 ? (
                    // Monthy
                    <Grid item container display={"flex"}>
                        <Grid item display={"flex"} xs={12} md={6}>
                            <Box sx={{ width: "100%" }}>
                                <Typography sx={{ fontWeight: "bold" }}>Premium Plan - Company</Typography>
                                <Typography sx={{ fontWeight: "bold" }}>RM1,495.00 / month</Typography>
                                <Typography sx={{ marginTop: "8px" }}>✅ DJC Leads Management</Typography>
                                <Typography>✅ DJC Whatsapp Broadcast</Typography>
                                <Typography>✅ DJC Whatsapp AI Flows</Typography>
                                <Typography>✅ DJC Whatsapp Sequences</Typography>
                                <Typography>✅ DJC Email Marketing</Typography>
                                <Typography>✅ DJC Landing Page Builder</Typography>
                                <Typography>✅ DJC Training Courses</Typography>
                                <Typography>✅ Continuous Enhancements</Typography>
                                <Typography>✅ Rapid System Support</Typography>
                                <Typography>✅ * 15 accounts - 1 main + 14 sub accounts</Typography>
                                <Typography>✅ * Property Listing Portal Access</Typography>

                                <Button
                                    variant="contained"
                                    onClick={() => loadStripe("price_1PsH36LWoLTwDp2ICP7rTZyy")}
                                    sx={{ marginTop: "8px", marginBottom: "8px" }}
                                >
                                    Subscribe
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    // Yearly
                    <Grid item container display={"flex"}>
                        <Grid item display={"flex"} xs={12} md={6}>
                            <Box sx={{ width: "100%" }}>
                                <Typography sx={{ fontWeight: "bold" }}>Premium Plan - Company</Typography>
                                <Typography sx={{ fontWeight: "bold" }}>RM239.20 x 12</Typography>
                                <Typography sx={{ marginTop: "8px" }}>✅ DJC Leads Management</Typography>
                                <Typography>✅ DJC Whatsapp Broadcast</Typography>
                                <Typography>✅ DJC Whatsapp AI Flows</Typography>
                                <Typography>✅ DJC Whatsapp Sequences</Typography>
                                <Typography>✅ DJC Email Marketing</Typography>
                                <Typography>✅ DJC Landing Page Builder</Typography>
                                <Typography>✅ DJC Training Courses</Typography>
                                <Typography>✅ Continuous Enhancements</Typography>
                                <Typography>✅ Rapid System Support</Typography>
                                <Typography>✅ * Add up to 9 team members</Typography>
                                <Typography>✅ * Property Listing Portal Access</Typography>

                                <Button
                                    variant="contained"
                                    onClick={() => loadStripe("price_1OsiYbLWoLTwDp2I1K8beJV4")}
                                    disabled={
                                        (user.addOn === "whatsappCloud" || loading) &&
                                        user.role !== "Super Admin"
                                    }
                                    sx={{ marginTop: "8px", marginBottom: "8px" }}
                                >
                                    Subscribe
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                )}

                <Loading loading={loading} />
                <GridDivider />
                {url && <CheckOut />}
            </GridContainer>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Container>
    );
}
