import axios from "axios";
import { mapLeads } from "./mapLeads";

export async function getLeadFormData(formId, pageAccessToken) {
    try {
        const url = `https://graph.facebook.com/v21.0/${formId}/leads?access_token=${pageAccessToken}`;
        const response = await axios(url);
        const leads = mapLeads(response.data.data);
        console.log(leads);
        return { leads: leads, unmappedLeads: response.data.data };
    } catch (err) {
        console.log(err);
    }
}
