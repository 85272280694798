import { useTheme } from "@emotion/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PushPinIcon from "@mui/icons-material/PushPin";
import ReplyIcon from "@mui/icons-material/Reply";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { Box, CardMedia, Dialog, IconButton, Menu, MenuItem, Typography, Tooltip, Icon } from "@mui/material";
import { styled } from "@mui/system";
import { deleteDoc, doc } from "firebase/firestore";
import { useContext, useState } from "react";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import extractNumbersOnly from "../../functions/common-functions/extractNumbersOnly";
import formatDate from "../../functions/common-functions/formatDate";
import useColors from "../../hooks/useColors";
import useWindowSize from "../../hooks/useWindowSize";
import formatWhatsAppStyle from "../../pages/MessengerInboxPage/formatWhatsappStyle";
import convertDate from "../../utils-functions/convertDate";
import PollComponent from "../../pages/MessengerInboxPage/PollComponent";

// Create or import QuotedMessageComponent
const QuotedMessageBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#e0f7fa" : "#424242", // Light cyan for light mode, dark grey for dark mode
    padding: "8px",
    borderLeft: "3px solid #34b7f1", // WhatsApp-like blue border
    borderRadius: "5px",
    marginBottom: "8px",
    whiteSpace: "pre-wrap", // Ensure text wraps correctly
    wordBreak: "break-word", // Ensure long words break properly
}));

const QuotedMessageComponent = ({ message }) => {
    const theme = useTheme();
    return (
        <>
            <QuotedMessageBox>
                <Typography
                    variant="body2"
                    color={theme.palette.mode === "light" ? "textSecondary" : "textPrimary"}
                    sx={{ fontWeight: "bold" }}
                >
                    {message?.caption?.sender || message?.quotedMessage?.participant?.split("@")[0] || ""}
                </Typography>
                <Typography
                    variant="body1"
                    color={theme.palette.mode === "light" ? "textPrimary" : "textSecondary"}
                >
                    {message?.quotedMessage?.quotedMessage}
                </Typography>
            </QuotedMessageBox>
        </>
    );
};

export default function ChatMessageComponent({
    // fileName,
    message,
    platform = "whatsapp",
    clientName = "",
    setReplyMessage = () => {},
    handleOpenForwardMsg = () => {},
}) {
    const theme = useTheme();
    const colorMode = theme.palette.mode;
    const { user } = useContext(AuthContext);
    const [openImage, setOpenImage] = useState(false);
    const [videoError, setVideoError] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const myNumber = message?.chatRoomId?.split("-")[0];
    const from = extractNumbersOnly(message.from);
    const fromMe = myNumber === from;

    const colors = useColors(platform);
    const windowSize = useWindowSize();

    const handleReply = (message) => {
        console.log(message);
        let messageBody = "";
        if (message.type === "poll_creation") {
            messageBody =
                "Poll : " + message.pollCreation.pollCreationMessage.name ||
                message.pollCreation.pollCreationMessageV3.name;
        } else {
            messageBody = message.body;
        }
        setReplyMessage({
            sender: message.pushname || message.userName || message.from || "Direct Reply From Phone",
            quotedMessage: messageBody,
            type: "reply",
            quotedMessageId: message.messageId || "",
            remoteJid: message.remoteJid,
        });
        handleCloseMenu();
    };

    const handleForward = async (message) => {
        console.log(message);
        handleOpenForwardMsg(message);
        handleCloseMenu();
    };

    const handleClickImage = (message) => {
        console.log(message);
        setOpenImage(true);
    };

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const checkMedia = (message) => {
        if (message.type === "ptt") {
            return (
                <audio controls>
                    <source src={message.downloadUrl} />
                    Your browser does not support the audio element.
                </audio>
            );
            //
        } else if (message.type === "image" || message.type === "sticker") {
            const size = () => {
                return message.type === "sticker" ? { width: "80px" } : { width: "200px" };
            };
            return (
                <>
                    <CardMedia
                        component="img"
                        image={message.downloadUrl}
                        alt="file"
                        sx={{ width: size(), cursor: "pointer" }}
                        onClick={() => handleClickImage(message)}
                    />
                    {/* <Typography>fileName</Typography> */}
                </>
            );
        } else if (message.type === "audio") {
        
            return (
                <>
                    <Box
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <audio controls>
                            <source src={message.downloadUrl} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </Box>
                </>
            );
        } else if (
            message.type === "story_mention" ||
            message.type === "video" ||
            message.type === "story_reply"
        ) {
            if (videoError) {
                return <Typography>Story has expired</Typography>;
            }
            return (
                <>
                    <video controls width="250" onError={() => setVideoError(true)}>
                        <source src={message.downloadUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <Box>
                        <Typography variant="caption">
                            {message.type === "story_mention"
                                ? "Story mentioned"
                                : message.type === "story_reply" && "Story reply"}
                        </Typography>
                    </Box>
                </>
            );
        } else {
            return (
                <Tooltip title={message.filePath?.split("/").pop()}>
                    <Box
                        onClick={() => {
                            // Open the document in a new tab
                            window.open(message.downloadUrl, "_blank");
                        }}
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {renderFileIcon(message)}
                        <Typography
                            variant="caption"
                            sx={{
                                marginLeft: 1,
                            }}
                        >
                            {message.filePath?.split("/").pop().length > 20
                                ? message.filePath?.split("/").pop().slice(0, 20) + "..."
                                : message.filePath?.split("/").pop()}
                        </Typography>
                    </Box>
                </Tooltip>
            );
        }
    };

    const confirmation = useConfirmation();

    const handleClickDelete = async (id) => {
        const response = await confirmation(
            "Delete message",
            "Are you sure you want to delete this message?"
        );
        if (!response) return;

        let docRef;
        if (message.scheduled) {
            docRef = doc(db, "scheduledTasks", id);
        } else {
            docRef = doc(db, "fbMessages", message.chatRoomId, "messages", id);
        }

        try {
            await deleteDoc(docRef);
            console.log("Message deleted successfully");
        } catch (error) {
            console.log("Error deleting message:", error);
        }
    };

    return (
        !message.is_deleted && (
            <Box display="flex" flexDirection={"column"}>
                <Box
                    p="8px"
                    display="flex"
                    justifyContent={fromMe ? "flex-end" : "flex-start"}
                    sx={{
                        paddingRight: { xs: "30px", md: "60px" },
                        paddingLeft: { xs: "8px", md: "20px" },
                        marginBottom: "8px",
                    }}
                >
                    <Box
                        onClick={() => console.log(message)}
                        sx={{
                            position: "relative",
                            background: fromMe ? colors.textBoxBackgroundMe : colors.textBoxBackgroundClient,
                            borderRadius: "8px",
                            maxWidth: "85%",
                            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
                            opacity: message.scheduled ? 0.5 : 1,
                        }}
                        p={message.type === "template" ? "" : "8px"}
                    >
                        {message.quotedMessage && Object.keys(message.quotedMessage).length > 0 && (
                            <QuotedMessageComponent message={message} />
                        )}

                        {message.downloadUrl && checkMedia(message)}
                      

                        {message.type === "template" && message.object === "page" && (
                            <PollComponent message={message} />
                        )}
                        {message.type === "template" && message.object === "instagram" && (
                            <PollComponent message={message} />
                        )}

                        <Typography
                            sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                            color={fromMe ? colors.textColorMe : colors.textColorClient}
                        >
                            {message.body || message?.params?.message
                                ? formatWhatsAppStyle(message.body || message?.params?.message)
                                : ""}
                        </Typography>

                        <IconButton
                            sx={{
                                position: "absolute",
                                top: "50%",
                                right: -30,
                                transform: "translateY(-50%)",
                                color: "gray",
                            }}
                            size="small"
                            onClick={handleClickMenu}
                        >
                            <MoreVertIcon />
                        </IconButton>

                        <Typography
                            variant="caption"
                            color="textSecondary"
                            display={"flex"}
                            sx={{
                                position: "absolute",
                                justifyContent: fromMe ? "flex-end" : "flex-start",
                                width: "400px",
                                bottom: -17,
                                color: "gray",
                                left: fromMe ? "auto" : "0",
                                right: fromMe ? "0" : "auto",
                                px: "4px",
                            }}
                        >
                            <Box component="span" sx={{ marginRight: "8px" }}>
                                {message.scheduled
                                    ? "Scheduled"
                                    : message.pushname ||
                                      message.userName ||
                                      message.sender ||
                                      "Direct from phone"}
                            </Box>

                            {`${formatDate(convertDate(message.date), true)}`}
                        </Typography>

                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClickDelete(message.id);
                                    handleCloseMenu();
                                }}
                            >
                                <Icon sx={{ marginRight: "10px" }} color="error">
                                    delete
                                </Icon>
                                Delete
                            </MenuItem>

                            {/* <MenuItem
                                onClick={() => {
                                    handleReply(message);
                                    handleCloseMenu();
                                }}
                            >
                                <ReplyIcon sx={{ marginRight: "10px" }} color="error" />
                                Reply
                            </MenuItem>

                            <MenuItem
                                onClick={() => {
                                    handleForward(message);
                                    handleCloseMenu();
                                }}
                            >
                                <ShortcutIcon sx={{ marginRight: "10px" }} color="error" />
                                Forward
                            </MenuItem> */}
                            {/* <MenuItem
                                onClick={() => {
                                    handleCloseMenu();
                                }}
                            >
                                <PushPinIcon sx={{ marginRight: "10px" }} color="error" />
                                Pin
                            </MenuItem> */}
                        </Menu>
                    </Box>

                    <Dialog open={openImage} onClose={() => setOpenImage(false)}>
                        <CardMedia
                            component="img"
                            image={message.downloadUrl}
                            alt="file"
                            sx={{
                                width: "100%", // Take the full width of the parent
                                height: "100%", // Take the full height of the parent
                                objectFit: "contain", // Adjust this based on your preference
                            }}
                        />
                    </Dialog>
                </Box>
            </Box>
        )
    );
}

const renderFileIcon = (message) => {
    let color;
    let label;
    const fileExtension = getFileExtension(message.downloadUrl);

    switch (fileExtension.toLowerCase()) {
        case "pdf":
            color = "#FF0000";
            label = "PDF";
            break;
        case "doc":
        case "docx":
            color = "#0000FF";
            label = "DOC";
            break;
        case "xls":
        case "xlsx":
            color = "#008000";
            label = "XLSX";
            break;
        default:
            color = `#001100`;
            label = fileExtension.toUpperCase();
    }

    return (
        <Box
            component="svg"
            viewBox="0 0 24 24"
            sx={{
                height: 60, // Adjust the size as needed
                borderRadius: "6px",
            }}
        >
            <rect width="24" height="24" fill={color} />
            <text x="4" y="17" fill="#FFFFFF" fontSize="8" fontFamily="Arial, Helvetica, sans-serif">
                {label}
            </text>
        </Box>
    );
};

// Function to get the filename and file extension
const getFileExtension = (path) => {
    if (!path) {
        return "";
    }

    const lastDotIndex = path.lastIndexOf(".");
    if (lastDotIndex === -1) return ""; // No dot found
    return path.substring(lastDotIndex + 1);
};
