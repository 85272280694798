import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getMessaging } from "firebase/messaging";
import { getStorage } from "firebase/storage";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

// Staging database
const firebaseConfig_staging = {
    apiKey: "AIzaSyD9efNzRLffpQtHJSu9Ec71ZIY6Oi1RE_Y",
    authDomain: "djc-leads-v2-staging.firebaseapp.com",
    projectId: "djc-leads-v2-staging",
    storageBucket: "djc-leads-v2-staging.appspot.com",
    messagingSenderId: "136484783097",
    appId: "1:136484783097:web:afb977d8ac8a0ed4a4b328",
};

// main database
const firebaseConfig_production = {
    apiKey: "AIzaSyA_RK0S_PuVgWbbCcTJnrT2oSg6rIY_lBI",
    authDomain: "facebook-api-59e5c.firebaseapp.com",
    projectId: "facebook-api-59e5c",
    storageBucket: "facebook-api-59e5c.appspot.com",
    messagingSenderId: "755110168979",
    appId: "1:755110168979:web:adee74edf0f24a38f18066",
    measurementId: "G-RCTGK6NHBV",
};

const stage = process.env.REACT_APP_STAGE;
if (stage === "production") {
    var firebaseConfig = firebaseConfig_production;
} else {
    firebaseConfig = firebaseConfig_staging;
}

const app = initializeApp(firebaseConfig);
console.log("Using firebaseConfig: ", stage);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, "asia-southeast1");
export const dbRT = getDatabase();
export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);

const vertexAI = getVertexAI(app);
const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash-preview-0514" });

export { model };

logEvent(analytics, "notification_received");

export default app;
