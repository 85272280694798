import { collection, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import { arrayIsEmpty } from "./../../utils-functions/arrayIsEmpty";
import convertDate from "./../../utils-functions/convertDate";
import mapDocSnapshot from "./../../utils-functions/mapDocSnapshot";

export default async function getSubscription(id) {
    try {
        const collectionRef = collection(db, "customers");
        const q = query(collectionRef, where("email", "==", id));
        const querySnapshot = await getDocs(q);
        const customer = mapSnapshot(querySnapshot)[0];
        if (customer) {
            const collectionRef = collection(db, "customers", customer.id, "subscriptions");
            const subscriptionsSnapshot = await getDocs(collectionRef);
            const subscriptions = mapSnapshot(subscriptionsSnapshot);

            const paymentsRef = collection(db, "customers", customer.id, "payments");
            const paymentsSnapshot = await getDocs(paymentsRef);
            const payments = mapSnapshot(paymentsSnapshot);
            const newPayments = payments.map((payment) => {
                if (payment.amount_received) {
                    return {
                        amount_received: payment.amount_received || "",
                        date: new Date(payment.created * 1000) || "",
                    };
                } else {
                    return null;
                }
            });

            if (subscriptions.length !== 0) {
                const activeSubscription = subscriptions.find((sub) => sub.status === "active");

                if (activeSubscription) {
                    const priceRef = activeSubscription.price;
                    const priceSnapshot = await getDoc(priceRef);
                    const price = mapDocSnapshot(priceSnapshot);

                    customer.subscriptionStatus = activeSubscription.status;
                    customer.trialEnd = convertDate(activeSubscription.trial_end);
                    customer.date = convertDate(activeSubscription.created);

                    customer.price = price?.unit_amount / 100 || 0;
                    customer.currency = price?.currency;
                    customer.paymentInterval = price?.interval;
                } else {
                    const priceRef = subscriptions[0].price;
                    const priceSnapshot = await getDoc(priceRef);
                    const price = mapDocSnapshot(priceSnapshot);

                    customer.subscriptionStatus = subscriptions[0].status;
                    customer.trialEnd = convertDate(subscriptions[0].trial_end);
                    customer.date = convertDate(subscriptions[0].created);

                    customer.price = price?.unit_amount / 100 || 0;
                    customer.currency = price?.currency;
                    customer.paymentInterval = price?.interval;
                }
            }

            customer.payments = newPayments;
            newPayments.forEach((payment) => {
                if (customer.subscriptionStatus === "active" || customer.subscriptionStatus === "trialing") {
                    if (payment) {
                        if (customer.paymentInterval === "month") {
                            customer.mrr = customer["price"];
                            // Hard code to account for discounts
                            if (payment.amount_received / 100 === 99) customer.mrr = 99;
                            if (payment.amount_received / 100 === 69) customer.mrr = 69;
                            if (payment.amount_received / 100 === 62) customer.mrr = 62;
                            if (payment.amount_received / 100 === 9) customer.mrr = 9;
                        } else {
                            customer.mrr = customer["price"] / 12;
                        }
                    } else {
                        customer.mrr = calculteMrr(customer) || 0;
                    }
                } else {
                    customer.mrr = 0;
                }
            });
            if (arrayIsEmpty(newPayments)) {
                customer.mrr = calculteMrr(customer) || 0;
            }
        }
        if (customer) {
            return customer;
        } else {
            return {};
        }
    } catch (error) {
        console.log(error);
    }
}

const calculteMrr = (referee) => {
    if (referee.subscriptionStatus === "active" || referee.subscriptionStatus === "trialing") {
        if (referee.paymentInterval === "year") {
            return referee.price / 12;
        } else {
            return referee.price;
        }
    } else {
        return 0;
    }
};
