import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";

import { toast } from "react-hot-toast";
import ChatIcon from "@mui/icons-material/Chat";
import { Avatar, Box, Chip, Dialog, Icon, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";
import useColors from "../../hooks/useColors";
import FlowComponent from "./FlowComponent";
import formatWhatsAppStyle from "./formatWhatsappStyle";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { storage } from "../../firebase/firebase-utils";
import { collection, deleteDoc, getDocs } from "firebase/firestore";
import { deleteObject, getMetadata, ref } from "firebase/storage";
import Tooltip from "@mui/material/Tooltip";

export function ChatRoomComponent({ chatRoom = {}, handleClick }) {
    const [typographyWidth, setTypographyWidth] = useState("auto");
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const confirmation = useConfirmation();
    const number = chatRoom?.id?.split("-")[0] || "";

    const { user } = useContext(AuthContext);

    const containerRef = useRef(null);

    const colors = useColors();

    useEffect(() => {
        const calculateWidth = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const iconButtonWidth = 52; // Adjust this based on actual IconButton size
                const calculatedWidth = containerWidth - iconButtonWidth - 8 * 2; // Subtract IconButton width and margins
                setTypographyWidth(calculatedWidth);
            }
        };

        // Calculate on mount and window resize
        calculateWidth();
        window.addEventListener("resize", calculateWidth);

        // Cleanup
        return () => window.removeEventListener("resize", calculateWidth);
    });

    const messageToDisplay = () => {
        if (chatRoom?.lastMessageType === "ptt") return "Voice message 🎤";
        if (chatRoom?.lastMessageType === "image") return "Image 📷";

        const lastMessage = chatRoom?.lastMessage;
        if (!lastMessage) return "";

        return lastMessage ? formatWhatsAppStyle(lastMessage) : "";
    };

    const handleClickMore = (event, chatRoom) => {
        setAnchorEl(event.currentTarget);
        console.log("clicked more", chatRoom);
        event.stopPropagation();
    };

    const handleClickFlow = (event) => {
        setAnchorEl(null);
        setOpenDialog(true);
    };

    const handleCloseMenu = (e) => {
        setAnchorEl(null);
        e.stopPropagation();
    };

    const handleDeleteFlow = async () => {
        const docRef = doc(db, "fbMessages", chatRoom.id);
        await updateDoc(docRef, { flowId: "", flowUserId: "", flowName: "" });
        toast.success("Flow deleted successfully");
        setAnchorEl(null);
    };

    const handleToggleFlow = async () => {
        const docRef = doc(db, "fbMessages", chatRoom.id);
        await updateDoc(docRef, {
            disableDefaultFlow: !chatRoom.disableDefaultFlow,
        });
        toast.success(chatRoom.disableDefaultFlow ? "Default Flow enabled" : "Default Flow disabled");
        setAnchorEl(null);
    };

    const handleClickAssign = async (currentFlowId, currentFlow) => {
        const docRef = doc(db, "fbMessages", chatRoom.id);
        await updateDoc(docRef, { flowId: currentFlowId, flowUserId: user.id, flowName: currentFlow.name });
        toast.success("Flow assigned successfully");
        setOpenDialog(null);
    };

    const handleMenuItemClick = async (e, item) => {
        try {
            if (item === "Delete Chat Room") {
                const response = await confirmation(
                    "Delete Chat Room",
                    "Are you sure you want to delete this chat room?"
                );
                if (!response) return;
                console.log("chatRoom.id:", chatRoom.id);
                const messagesCollection = collection(db, `fbMessages/${chatRoom.id}/messages`);

                // Retrieve all messages
                const messagesSnapshot = await getDocs(messagesCollection);
                const deletionPromises = messagesSnapshot.docs.map(async (docSnapshot) => {
                    try {
                        const message = docSnapshot.data();
                        if (message.downloadUrl) {
                            console.log(message.downloadUrl);
                            // If there's a download URL, delete the file from storage
                            const fileRef = ref(storage, message.downloadUrl);
                            await deleteObject(fileRef);
                        }
                        // Delete the document
                        return deleteDoc(docSnapshot.ref);
                    } catch (error) {
                        console.error("Error deleting message:", error);
                    }
                });

                // Wait for all messages to be deleted
                await Promise.all(deletionPromises);

                // Delete the chat room itself
                const chatRoomRef = doc(db, "fbMessages", chatRoom.id);
                await deleteDoc(chatRoomRef);
                console.log("Chat room deleted successfully");
                toast.success("Chat room deleted successfully");
            } else if (item === "Clear Chat") {
                const response = await confirmation(
                    "Clear Chat",
                    "Are you sure you want to clear this chat?"
                );
                if (!response) return;
                console.log("chatRoom.id:", chatRoom.id);
                const messagesCollection = collection(db, `fbMessages/${chatRoom.id}/messages`);

                // Retrieve all messages
                const messagesSnapshot = await getDocs(messagesCollection);
                const deletionPromises = messagesSnapshot.docs.map(async (docSnapshot) => {
                    try {
                        const message = docSnapshot.data();
                        if (message.downloadUrl) {
                            console.log(message.downloadUrl);
                            // If there's a download URL, attempt to delete the file from storage
                            try {
                                const fileRef = ref(storage, message.downloadUrl);
                                await deleteObject(fileRef);
                                console.log("File deleted successfully");
                            } catch (error) {
                                console.log("File does not exist or could not be deleted:", error.message);
                                // Continue with document deletion even if file deletion fails
                            }
                        }
                        // Delete the document regardless of file existence or deletion success
                        await deleteDoc(docSnapshot.ref);
                        console.log("Document deleted successfully");
                    } catch (error) {
                        console.error("Error processing message:", error);
                        // You might want to throw this error if you want to halt the entire process
                        // throw error;
                    }
                });

                // Wait for all messages to be deleted
                await Promise.all(deletionPromises);
                toast.success("Chat cleared successfully");
            } else {
                e.stopPropagation();
            }
        } catch (error) {
            console.error("Error in handleMenuItemClick:", error);
            toast.error("An error occurred. Please try again.");
        } finally {
            setAnchorEl(false);
        }
    };

    return (
        <Tooltip title={`${chatRoom.name} - ${chatRoom?.id.split("-")[1]}`} placement="top">
            <Box
                display="flex"
                flexDirection={"column"}
                width={"100%"}
                sx={{
                    borderBottom: `1px solid ${colors.topBarLine}`,
                    "&:hover": {
                        backgroundColor: colors.boxHighlight, // Replace 'primary.main' with your desired color
                    },
                    cursor: "pointer",
                }}
            >
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    width={"100%"}
                    onClick={() => handleClick(chatRoom)}
                    justifyContent={"space-between"}
                    px="4px"
                >
                    <Box p="8px" display="flex" width={"100%"} ref={containerRef}>
                        <Box>
                            <Avatar sx={{ width: "45px", height: "45px" }} src={chatRoom?.profilePic || ""} />
                        </Box>
                        <Box display="flex" flexDirection={"column"} p="0 8px 0 8px" width={"100%"}>
                            <Box
                                display="flex"
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                width={`${typographyWidth}px`}
                            >
                                <Typography
                                    variant="body1"
                                    flex={1}
                                    noWrap
                                    sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        wordBreak: "break-word", // Break long words
                                    }}
                                >
                                    {chatRoom?.name || chatRoom?.id.split("-")[1]}
                                </Typography>
                                <Typography variant="caption" color="#71818a">
                                    {chatRoom?.date ? formatDate(convertDate(chatRoom.date), true) : ""}
                                </Typography>
                            </Box>

                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width={`${typographyWidth}px`} // Set width to calculated width because nowrap only works with fixed width
                            >
                                <Typography
                                    variant="body2"
                                    flex={1}
                                    noWrap
                                    sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        wordBreak: "break-word", // Break long words
                                    }}
                                >
                                    {messageToDisplay()}
                                </Typography>
                                {Boolean(chatRoom.unreadAI) && (
                                    <Chip label={chatRoom.unreadAI} color="error" />
                                )}
                                {Boolean(chatRoom.unread) && <Chip label={chatRoom.unread} color="primary" />}
                                <IconButton
                                    size="small"
                                    onClick={(event) => handleClickMore(event, chatRoom)}
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                    <MenuItem onClick={handleClickFlow}>
                                        <Box display="flex" gap={1} alignContent={"center"}>
                                            <Icon>assignment_return</Icon>
                                            <Typography>Assign AI Flow</Typography>
                                        </Box>
                                    </MenuItem>
                                    <MenuItem onClick={handleDeleteFlow}>
                                        <Box display="flex" gap={1} alignContent={"center"}>
                                            <Icon color="error">delete</Icon>
                                            <Typography>Delete AI Flow</Typography>
                                        </Box>
                                    </MenuItem>
                                    <MenuItem onClick={handleToggleFlow}>
                                        <Box display="flex" gap={1} alignItems="center">
                                            {chatRoom.disableDefaultFlow ? (
                                                <Icon color="primary">speaker_notes</Icon>
                                            ) : (
                                                <SpeakerNotesOffIcon color="error" />
                                            )}
                                            <Typography>
                                                {chatRoom.disableDefaultFlow ? "Enable" : "Disable"} AI Flow
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                    <MenuItem onClick={(e) => handleMenuItemClick(e, "Clear Chat")}>
                                        <Box display="flex" gap={1} alignContent={"center"}>
                                            <ChatIcon color="error" />
                                            <Typography>Clear Chat</Typography>
                                        </Box>
                                    </MenuItem>
                                    <MenuItem onClick={(e) => handleMenuItemClick(e, "Delete Chat Room")}>
                                        <Box display="flex" gap={1} alignContent={"center"}>
                                            <CommentsDisabledIcon color="error" />
                                            <Typography>Delete Chat Room</Typography>
                                        </Box>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box px={1} pb={1}>
                    {chatRoom?.flowName && (
                        <Chip
                            label={`${chatRoom?.flowName} - ${chatRoom?.flowUserId}`}
                            color="primary"
                            size="small"
                        />
                    )}{" "}
                </Box>
                <Box px={1} pb={1}>
                    {chatRoom?.disableDefaultFlow && (
                        <Chip label="Default Flow Disabled" color="error" size="small" />
                    )}{" "}
                </Box>
                <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    PaperProps={{
                        sx: {
                            width: { xs: "99vw", md: "70vw" }, // Adjust breakpoints as needed
                            height: "71vh",
                            maxWidth: "100%", // Ensure the dialog doesn't exceed the screen width
                        },
                    }}
                >
                    <FlowComponent user={user} onClose={handleClickAssign} />
                </Dialog>
            </Box>
        </Tooltip>
    );
}
