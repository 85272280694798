import { Box, Button, Dialog, Icon, IconButton, TextField } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useConfirmation } from "../context-utils/ConfirmationContext";
import { InputContext, useInput } from "../context-utils/InputContext";
import { AuthContext } from "../context/AuthContext";
import { db } from "../firebase/firebase-utils";
import editField from "../functions/editField";
import { GridDivider, GridFlexBox, IconC, Name } from "../themes/themes";
import isAdmin from "../utils-functions/isAdmin";
import convertDate from "../utils-functions/convertDate";
import formatDate from "../functions/common-functions/formatDate";

const KEYS = {
    displayName: "Display Name",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    chatId: "Chat ID",
    referralCode: "Referral Code",
};

export default function DataFields({ object, collectionId, keys_ = KEYS, handleUpdate, state }) {
    const [keys, setKeys] = useState(keys_);
    const [open, setOpen] = useState(false);
    const { user } = useContext(AuthContext);
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");

    const { openInputDialog } = useContext(InputContext);

    const input = useInput();
    const confirmation = useConfirmation();

    function isUserProfile(obj) {
        return obj && typeof obj === "object" && "displayName" in obj;
    }

    useEffect(() => {
        if (key && user && isUserProfile(user)) {
            const jsonValue = JSON.stringify(user[key], null, 2);
            setValue(jsonValue);
        }
    }, [key, user]);

    useEffect(() => {
        if (object) {
            let newKeys = {};
            Object.keys(keys_).forEach((key) => {
                newKeys[key] = keys[key];
            });
            let extraKeys = {};

            if (isAdmin(user, state)) {
                Object.keys(object).forEach((key) => {
                    if (display(object, key)) {
                        if (!newKeys[key]) {
                            extraKeys[key] = key;
                        }
                    }
                });
            }

            // Sort extraKeys alphabetically by key
            extraKeys = Object.keys(extraKeys)
                .sort()
                .reduce((sortedObj, key) => {
                    sortedObj[key] = extraKeys[key];
                    return sortedObj;
                }, {});

            setKeys({ ...newKeys, ...extraKeys });
        }
    }, [object]);

    const handleAddField = async () => {
        const fieldName = await input("Add Field", "Add a custom field name", "field name", "");
        const fieldValue = await input("Add Field", "Add a custom field value", "field value", "");
        console.log(fieldName, fieldValue);
        const response = await confirmation("Confirmed?", `${fieldName}: ${fieldValue}`);
        if (response) {
            await editField(user, fieldName, fieldValue, "users");
            handleUpdate(object, fieldName, fieldValue);
        }
    };

    const canEdit = (key) => {
        if (Array.isArray(user?.[key])) {
            return false;
        }
        if (typeof user?.[key] === "object") {
            return false;
        }
        if (typeof user?.[key] === "boolean") {
            return false;
        }

        return true;
    };

    const handleEdit = async (key) => {
        if (!user) return;

        const userRef = doc(db, "users", user.id);
        if (canEdit(key)) {
            const response = await input("Edit value", "", "New Value", user[key]);
            if (response || response === "") {
                await updateDoc(userRef, { [key]: response });
                toast.success("User updated");
            }
        } else {
            setOpen(true);
            setKey(key);
        }
    };

    const handleSave = async () => {
        if (!user) return;

        const userRef = doc(db, "users", user.id);
        try {
            const parsedValue = JSON.parse(value);
            await updateDoc(userRef, { [key]: parsedValue });
            toast.success("User updated");
            setOpen(false);
        } catch (error) {
            toast.error("Invalid JSON format");
        }
    };

    const handleAddArray = async () => {
        const fieldName = await input("Add Array", "Add a custom array name", "field name", "");
        if (fieldName) {
            const response = await confirmation("Confirmed?", `${fieldName}: []`);
            if (response) {
                const docRef = doc(db, "users", user.id);
                await updateDoc(docRef, { [fieldName]: [] });
            }
        }
    };

    return (
        <React.Fragment>
            {object &&
                Object.keys(keys).map((key, i) => (
                    <React.Fragment key={key}>
                        <GridFlexBox w="30%" fs>
                            <Name>{keys[key]}:</Name>
                        </GridFlexBox>
                        <GridFlexBox w="60%" fs>
                            <Name whiteSpace="nowrap">{display(object, key) || ""}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="10%" fe>
                            {key !== "clientId" &&
                                key !== "id" &&
                                key !== "email" &&
                                canEdit(user, state, key) && (
                                    <IconC onClick={() => handleEdit(key)}>edit</IconC>
                                )}
                        </GridFlexBox>
                        {i === 6 && <GridDivider />}
                    </React.Fragment>
                ))}

            {isAdmin(user, state) && (
                <GridFlexBox w="100%" fs gap={1}>
                    <Button variant="contained" size="small" onClick={handleAddField}>
                        Add Field
                    </Button>
                    <Button variant="contained" size="small" onClick={handleAddArray}>
                        Add Array
                    </Button>
                </GridFlexBox>
            )}
            <GridDivider />
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{ sx: { width: ["100vw", "800px"], maxWidth: "none" } }}
            >
                <Box m={2}>
                    <TextField
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        multiline
                        rows={20}
                        fullWidth
                    />
                </Box>
                <Box display="flex" justifyContent="flex-end" p={2} gap={2}>
                    <Button onClick={() => setOpen(false)} variant="contained" color="warning">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} variant="contained">
                        Save
                    </Button>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}

const display = (user, key) => {
    if (user && key) {
        // Check if the key exists in the user object
        if (!(key in user)) {
            console.warn(`Warning: Key "${key}" does not exist in user object.`);

            return "";
        }

        // Type assertion to tell TypeScript that 'key' is a valid key of 'user'
        const value = user[key];

        if (key === "date") {
            return formatDate(convertDate(value));
        }

        if (Array.isArray(value)) {
            return `[ ${value.join(", ")} ]`;
        }

        if (typeof value === "object" && value !== null) {
            return "{ Object }";
        }

        if (typeof value === "boolean") {
            return value ? "Yes" : "No";
        }

        if (value === null || value === undefined) {
            return "";
        }

        return String(value);
    }

    return "";
};
