import { Box, Typography, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useState, useMemo } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@mui/material';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// Hardcoded message data for testing
// const hardcodedMessage = {
//   mid: "m_1234567890",
//   text: "Please answer this poll:",
//   elements: [
//     JSON.stringify({
//       title: "What's your favorite programming language?",
//       buttons: [
//         { title: "JavaScript", payload: "js_option" },
//         { title: "Python", payload: "python_option" },
//         { title: "Java", payload: "java_option" },
//         { title: "C++", payload: "cpp_option" }
//       ]
//     })
//   ],
//   attachments: [],
//   created_time: 1621234567,
//   is_echo: false,
//   app_id: 1234567890,
//   metadata: null
// };

export default function PollComponent({ message }) {
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = useState(null);
  // Add this new state
  const [currentElementIndex, setCurrentElementIndex] = useState(0);

  const { question, options, imageUrl, subtitle } = useMemo(() => {
    console.log("Message received:", message);

    if (message.object === "instagram") {
        // Process Instagram format
        const element = message.elements[currentElementIndex] || message.elements[0];
        const allButtons = element.buttons || [];
        const result = {
            question: element?.title || "",
            options: allButtons.map((button) => {
                const payload = JSON.parse(button.payload);
                return {
                    value: payload.pollNodeId,
                    label: button.title,
                };
            }),
        };
        console.log("Instagram poll data:", result);
        return result;
    } else {
        // Process Facebook format
        const pollElements = message?.elements || [];
        console.log("pollElements", pollElements);
        
        const currentElement = pollElements[currentElementIndex] || pollElements[0];

        // Handle product template format (remove the isPollFormat check since we want to show all elements)
        const options =
            currentElement?.buttons?.map((button) => {
                try {
                    const payload = JSON.parse(button.payload);
                    return {
                        value: payload.pollNodeId,
                        label: button.title,
                    };
                } catch (error) {
                    console.error("Error parsing button payload:", error);
                    return {
                        value: button.payload,
                        label: button.title,
                    };
                }
            }) || [];

        const result = {
            question: currentElement?.title || "",
            subtitle: currentElement?.subtitle || "",
            options: options,
            imageUrl: currentElement?.image_url || null,
        };
        console.log("Facebook data:", result);
        return result;
    }
  }, [message, currentElementIndex]); // Add currentElementIndex to dependencies

  // Add these navigation handlers
  const handlePrevious = () => {
    setCurrentElementIndex(prev => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentElementIndex(prev => 
      Math.min((message?.elements?.length || 1) - 1, prev + 1)
    );
  };

  // If no valid options are found, don't render the poll
  if (options.length === 0) {
    console.error("No valid options found for the poll");
    return null;
  }

  return (
    <Box position="relative" width="250px">
      {message?.elements?.length > 1 && currentElementIndex > 0 && (
        <IconButton
          onClick={handlePrevious}
          sx={{
            position: 'absolute',
            left: -20,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
            zIndex: 1,
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
      )}

      {message?.elements?.length > 1 && currentElementIndex < message.elements.length - 1 && (
        <IconButton
          onClick={handleNext}
          sx={{
            position: 'absolute',
            right: -20,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
            zIndex: 1,
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}

      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="250px"
        p="16px"
        borderRadius="8px"
        border="1px solid #dcdcdc"
        sx={{ backgroundColor: theme.palette.mode === "light" ? "#404040" : "#333333" }}
      >
        {imageUrl && (
            <Box
                width="100%"
                height="220px"
                mb={2}
                borderRadius="4px"
                overflow="hidden"
            >
                <img
                    src={imageUrl}
                    alt={question}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            </Box>
        )}
        
        <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
            <Typography
                variant="h6"
                fontWeight="bold"
                color="white"
            >
                {question}
            </Typography>
            {subtitle && (
                <Typography
                    variant="body2"
                    color="white"
                    sx={{ mt: 1 }}
                >
                    {subtitle}
                </Typography>
            )}
            {/* <Box display="flex" alignItems="center" mt="4px" width="100%">
                <CheckCircleIcon style={{ color: "grey", marginRight: "4px", fontSize: "12px" }} />
                <Typography
                    variant="caption"
                    color={theme.palette.mode === "light" ? "grey" : "textPrimary"}
                >
                    Select one
                </Typography>
            </Box> */}
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
          {options.map((option, index) => (
            <Button
              key={index}
              variant="contained"
              onClick={() => setSelectedOption(option.value)}
              fullWidth
              sx={{
                mb: 1,
                justifyContent: "flex-start",
                backgroundColor:"#808080",
                color: "white",
                '&:hover': {
                  backgroundColor: "#666666",
                },
              }}
            >
              <Typography variant="body2" color="white">
                {option.label}
              </Typography>
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
