import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import { collection, deleteDoc, doc, getDoc, getDocs } from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import { db, storage } from "../../firebase/firebase-utils";
import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";
import useColors from "../../hooks/useColors";
import { Case, User } from "./CasesPage";
import MenuComponent from "./MenuComponent";

import { deleteObject, ref } from "firebase/storage";

export function CaseItemComponent({
    caseItem,
    handleClick,
    selected,
}: {
    caseItem: Case;
    handleClick: any;
    selected: boolean;
}) {
    const [typographyWidth, setTypographyWidth] = useState<number | "auto">("auto");

    const [showDeleteCaseDialog, setShowDeleteCaseDialog] = useState<boolean>(false);

    const handleCancelDeleteCase = () => {
        setShowDeleteCaseDialog(false);
    };

    const containerRef = useRef<HTMLDivElement>(null);
    const { user } = useContext(AuthContext) as { user: User };

    const colors = useColors();

    useEffect(() => {
        const calculateWidth = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const iconButtonWidth = 52; // Adjust this based on actual IconButton size
                const calculatedWidth = containerWidth - iconButtonWidth - 8 * 2; // Subtract IconButton width and margins
                setTypographyWidth(calculatedWidth);
            }
        };

        // Calculate on mount and window resize
        calculateWidth();
        window.addEventListener("resize", calculateWidth);

        // Cleanup
        return () => window.removeEventListener("resize", calculateWidth);
    });

    const messageToDisplay = () => {
        return caseItem?.prospectName;
    };

    const handleDeleteCase = async () => {
        if (!caseItem?.id) {
            toast.error("Cannot delete case: Invalid case ID");
            return;
        }

        try {
            // First check if user is company admin
            const companyRef = doc(db, "companies", caseItem.companyId);
            const companySnap = await getDoc(companyRef);

            if (!companySnap.exists()) {
                toast.error("Company not found");
                return;
            }

            const companyData = companySnap.data();
            const isCompanyAdmin = companyData.admins?.includes(user.id);
            const isCaseAdmin = caseItem.admins?.includes(user.id);

            if (isCompanyAdmin) {
                // Show confirmation dialog
                setShowDeleteCaseDialog(true);
                return;
            }

            if (isCaseAdmin) {
                // Show confirmation dialog
                setShowDeleteCaseDialog(true);
                return;
            }

            toast.error("Only company administrators or case admin can delete cases");
        } catch (error) {
            console.error("Error checking permissions:", error);
            toast.error("Error checking delete permissions");
        }
    };

    const handleConfirmDeleteCase = async () => {
        try {
            const caseId = caseItem?.id;
            if (!caseId) return;

            const docRef = doc(db, "cases", caseId);

            // 1. Get all comments and handle attached files
            const commentsCollectionRef = collection(db, "cases", caseId, "comments");
            const commentsSnapshot = await getDocs(commentsCollectionRef);

            // Delete all files associated with comments
            for (const commentDoc of commentsSnapshot.docs) {
                const commentData = commentDoc.data();

                if (commentData.files && Array.isArray(commentData.files)) {
                    await Promise.all(
                        commentData.files.map(async (file: any) => {
                            const fileRef = ref(storage, file.path);
                            try {
                                await deleteObject(fileRef);
                            } catch (error) {
                                console.error("Error deleting file:", error);
                            }
                        })
                    );
                }
                // Delete the comment
                await deleteDoc(doc(db, "cases", caseId, "comments", commentDoc.id));
            }

            await deleteDoc(docRef);
            toast.success("Case deleted successfully");
            setShowDeleteCaseDialog(false);
        } catch (error) {
            console.error("Error deleting case:", error);
            toast.error("Error deleting case");
        }
    };

    const handleClickMenu = async (e: any, item: string) => {
        e.stopPropagation();
        if (item === "Delete Case") {
            await handleDeleteCase();
        }
    };

    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
            onClick={() => handleClick(caseItem)}
            justifyContent={"space-between"}
            sx={{
                borderBottom: `1px solid ${colors.topBarLine}`,
                "&:hover": {
                    backgroundColor: colors.boxHighlight, // Replace 'primary.main' with your desired color
                },
                cursor: "pointer",
            }}
            p="4px"
        >
            <Box
                p="8px"
                display="flex"
                width={"100%"}
                ref={containerRef}
                sx={{ backgroundColor: selected ? colors.boxHighlight : "none" }}
            >
                <Box>
                    <Avatar sx={{ width: "45px", height: "45px" }} src={caseItem?.profilePicUrl || ""} />
                </Box>
                <Box display="flex" flexDirection={"column"} p="0 8px 0 8px" width={"100%"}>
                    <Box
                        display="flex"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        width={`${typographyWidth}px`}
                    >
                        <Typography
                            variant="body1"
                            flex={1}
                            noWrap
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-word", // Break long words
                            }}
                        >
                            {caseItem?.prospectName}
                        </Typography>
                        <Typography variant="caption" color="#71818a">
                            {caseItem?.date ? formatDate(convertDate(caseItem.date), true) : ""}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width={`${typographyWidth}px`} // Set width to calculated width because nowrap only works with fixed width
                    >
                        <Typography
                            variant="body2"
                            flex={1}
                            noWrap
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-word", // Break long words
                            }}
                        >
                            {messageToDisplay()}
                        </Typography>
                        {/* <IconButton size="small" onClick={(event) => handleClickMore(event, caseItem)}> */}
                        <IconButton size="small">
                            <MenuComponent
                                menuItems={["Delete Case"]}
                                type="dropdown"
                                onMenuItemClick={handleClickMenu}
                            />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Dialog open={showDeleteCaseDialog} onClose={handleCancelDeleteCase}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    This action cannot be undone. This will permanently delete the case and remove all
                    associated data.
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCancelDeleteCase}>Cancel</Button>
                    <Button onClick={handleConfirmDeleteCase} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
