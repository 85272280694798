import { Box, Button } from "@mui/material";

export default function TestComponent({ counter, handleClick, setCounter }) {
    return (
        <Box>
            <Box>
                <Button
                    onClick={() => {
                        setCounter((prev) => prev - 1);
                    }}
                >
                    Minus
                </Button>
                <Button onClick={() => handleClick("A")}>A</Button>
                <Button onClick={() => handleClick("B")}>B</Button>
                <Button onClick={() => handleClick("C")}>C</Button>
            </Box>
            <Box> {counter}</Box>
        </Box>
    );
}
