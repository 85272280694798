import KayakingIcon from "@mui/icons-material/Kayaking";
import { Box, Button, Chip, Icon, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useMemo } from "react";
import formatDate from "../../functions/common-functions/formatDate";
import formatNumber from "../../functions/common-functions/formatNumber";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import { PaymentInfo, SubscriptionInfo, UserType } from "./types";

const useColumns = ({
    user,
    rows,
    handleAddPayment,
    handleAddSubscription,
}: {
    user: UserType;
    rows: any[];
    handleAddPayment: any;
    handleAddSubscription: any;
}) => {
    //
    const columns = useMemo<GridColDef<any>[]>(
        //
        () => [
            {
                field: "displayName",
                headerName: "Name",
                width: 160,
                renderCell: (params) => (
                    <Box>
                        <Button onClick={() => console.log(params.row)}>{params.value}</Button>
                    </Box>
                ),
            },

            { field: "email", headerName: "Email", width: 180 },

            { field: "level", headerName: "Level", width: 60, align: "center" },

            { field: "referral", headerName: "Referral", width: 180 },

            {
                field: "addOn",
                headerName: "Status",
                width: 80,
                align: "center",
                valueFormatter: (value) => (value ? "active" : "inactive"),
                renderCell: (params) => {
                    if (params.value === "whatsappCloud") {
                        return (
                            <Box>
                                <Chip label="active" size="small" color="primary" />
                            </Box>
                        );
                    }

                    return "";
                },
            },

            {
                field: "payments",
                headerName: "Payments",
                width: 180,
                valueFormatter: (value) => {
                    if (!value) return "0";
                    let paymentMsg = "";
                    const payments = value as PaymentInfo[];
                    payments.slice(0, 1).forEach((payment: PaymentInfo) => {
                        paymentMsg += `${formatDate(payment.created)}: ${formatNumber(
                            payment.amount_received
                        )}\n`;
                    });
                    return paymentMsg.trim();
                },
                renderCell: (params) => {
                    const payments = params.value as PaymentInfo[];
                    if (!payments) return null;

                    return (
                        <Box sx={{ overflowY: "auto" }} height="100%" p={1}>
                            {payments.map((payment) => (
                                <Box
                                    key={payment.id}
                                    display="flex"
                                    alignItems="center"
                                    gap={1}
                                    justifyContent="space-around"
                                >
                                    <Typography variant="caption" flex={1}>
                                        {formatDate(payment.created)}
                                    </Typography>
                                    <Typography variant="caption">
                                        {formatNumber(payment.amount_received)}
                                    </Typography>
                                    {Boolean(payment.amount_refunded) && (
                                        <Typography variant="caption" color="red">
                                            {formatNumber(payment.amount_refunded)}
                                        </Typography>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    );
                },
            },

            {
                field: "totalPayment",
                headerName: "Total Payment",
                width: 80,
                type: "number",
                valueFormatter: (value) => (value ? formatNumber(value) : "0"),
                renderCell: (params) => {
                    return (
                        <Box height="100%" p={1}>
                            <Typography variant="body2">
                                {formatNumber(params.row.totalPayment || 0)}
                            </Typography>
                        </Box>
                    );
                },
            },

            {
                field: "mrr",
                headerName: "MRR",
                width: 80,
                type: "number",
                valueFormatter: (value) => (value ? formatNumber(value) : "0"),
                renderCell: (params) => {
                    return (
                        <Box height="100%" p={1}>
                            <Typography variant="body2">{formatNumber(params.row.mrr || 0)}</Typography>
                        </Box>
                    );
                },
            },

            {
                field: "subscriptions",
                headerName: "Subscriptions",
                width: 180,
                valueFormatter: (value) => {
                    if (arrayIsEmpty(value)) return "0";
                    const subscriptions = value as SubscriptionInfo[];
                    return subscriptions[0]?.amount || 0;
                },
                renderCell: (params) => {
                    const subscriptions = params.value as SubscriptionInfo[];
                    if (!subscriptions) return null;

                    return (
                        <Box sx={{ overflowY: "auto" }} height="100%" p={1}>
                            {subscriptions.map((subcription) => {
                                if (!subcription) return null;
                                if (subcription?.status === "canceled") return null;

                                return (
                                    <Box
                                        key={subcription.id}
                                        display="flex"
                                        alignItems="center"
                                        gap={1}
                                        justifyContent="space-around"
                                    >
                                        <Typography variant="caption" flex={1}>
                                            {formatDate(subcription.created)}
                                        </Typography>
                                        <Typography variant="caption">
                                            {formatNumber(subcription.amount)}
                                        </Typography>
                                        <Typography variant="caption">{subcription.interval}</Typography>
                                        {subcription.type === "manual" && (
                                            <Tooltip title="Manual Subscription">
                                                <KayakingIcon fontSize="small" />
                                            </Tooltip>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    );
                },
            },

            {
                field: "type",
                headerName: "Type",
                width: 80,
            },

            {
                field: "totalCommission",
                headerName: "Commission",
                width: 100,
                type: "number",
                valueFormatter: (value) => (value ? formatNumber(value) : "0"),
                renderCell: (params) => {
                    return (
                        <Box height="100%" p={1}>
                            <Typography variant="body2">
                                {formatNumber(params.row.totalCommission || 0)}
                            </Typography>
                        </Box>
                    );
                },
            },

            // actions buttons
            {
                field: "actions",
                type: "actions",
                width: 80,
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        icon={<Icon>add</Icon>}
                        key={`add-payment-${params.id}`}
                        label="Add Payment"
                        showInMenu
                        onClick={() => handleAddPayment(params.row)}
                    />,
                    // <GridActionsCellItem
                    //     icon={<Icon>add</Icon>}
                    //     key={`add-subscription-${params.id}`}
                    //     label="Add Subscription"
                    //     showInMenu
                    //     onClick={() => handleAddSubscription(params.row)}
                    // />,
                ],
            },
        ],
        [user, rows]
    );

    return columns;
};

export default useColumns;
