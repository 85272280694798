import { Button } from "@mui/material";

import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";

import { useConfirmation } from "../../context-utils/ConfirmationContext";
import useNotification from "../../context-utils/NotificationContext";
import { AuthContext } from "../../context/AuthContext";
import { readBlock, removePoll, updateBlock } from "./firebaseCall";
import FlowContext from "./FlowContext";

const PollNode = ({ data, isConnectable }) => {

    const confirmation = useConfirmation();
    const notify = useNotification();
    const { flow, updateTrigger, setUpdateTrigger } = useContext(FlowContext);
    const { user } = useContext(AuthContext);
    const [itemObj, setItemObj] = useState({});
    const [openFunction, setOpenFunction] = React.useState(false);
    const triggerRerender = () => {
        setUpdateTrigger((prev) => prev + 1); // Increment trigger to cause re-render
    };

    const handleClickOpenFunction = () => {
        setOpenFunction(true);
    };

    const handleCloseFunction = () => {
        setOpenFunction(false);
    };

    const handleSavePoll = async () => {
        if (!itemObj?.parentNode || itemObj.parentNode === "" || itemObj.parentNode === null) {
            notify("Parent node is missing or invalid.", "error");
            return;
        }
        const response = await confirmation("Save", "Are you sure you want to save?");
        if (response) {
            try {
                const parentBlk = await readBlock(user, flow, itemObj.parentNode);
                if (
                    parentBlk.pollObject.pollOptions
                        .map((option) => option.toLowerCase())
                        .includes(itemObj.name.toLowerCase())
                ) {
                    notify("Poll name cannot be the same.", "error");
                    return;
                }
                await updateBlock(user, flow, itemObj.blockId, itemObj);

                //update to parentNode pollObject.pollOptions
                // const parentBlk = await readBlock(user, flow, itemObj.parentNode);
                const arrPolls = parentBlk.arrPollItems;

                let newArrOptions = [];
                for (let i = 0; i < arrPolls.length; i++) {
                    const blkObj = await readBlock(user, flow, arrPolls[i]);
                    newArrOptions.push(blkObj.name);
                }

                let updateObj = {
                    pollObject: {
                        ...parentBlk.pollObject,
                        pollOptions: newArrOptions,
                    },
                };
                await updateBlock(user, flow, itemObj.parentNode, updateObj);

                notify("Data saved.", "success");
                triggerRerender();
            } catch (err) {
                console.log("handleSavePoll err :", err);
                notify("Save error.Please try again.", "error");
            } finally {
                handleCloseFunction();
            }
        }
    };

    // const handleClearFunction = () => {
    //     setItemObj({ ...itemObj, name: "" });
    // };

    const handleChange = (e) => {
        setItemObj({ ...itemObj, [e.target.name]: e.target.value });
    };

    const handleDeletePoll = async () => {
        const response = await confirmation("Delete", "Are you sure you want to delete poll selection?");
        if (response) {
            try {
                await removePoll(user, flow, itemObj);

                notify("Poll selection deleted.", "success");
                triggerRerender();
            } catch (err) {
                console.log("handleDeletePoll err :", err);
                notify("Delete error.Please try again.", "error");
            } finally {
                handleCloseFunction();
            }
        }
    };

    useEffect(() => {
        setItemObj(data);
    }, [data]);

    return (
        <>
            {data ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                        variant="contained"
                        sx={{
                            width: "295px",
                            height: "50px",
                            backgroundColor: "#14b53c",
                            "&:hover": { backgroundColor: "#10852d" },
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                        }}
                        // onClick={handleClickOpenFunction}
                    >
                        {data.name}
                    </Button>
                    <Handle
                        key={data.blockId}
                        type="source"
                        position={Position.Right}
                        isConnectable={isConnectable}
                        id={"POLL"}
                        style={{
                            background: "#525252",
                            width: "15px",
                            height: "15px",
                            borderRadius: "5px",
                            opacity: 0.5,
                        }}
                    />
                </div>
            ) : (
                ""
            )}
            <Dialog
                open={openFunction}
                onClose={handleCloseFunction}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth="sm" // Make sure this is a valid value
                fullWidth
            >
                <DialogTitle>Edit Poll name (max 80 characters)</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        id="name"
                        type="text"
                        name="name"
                        value={itemObj.name}
                        onChange={handleChange}
                        placeholder="Edit name"
                        inputProps={{ maxLength: 80 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeletePoll}>Delete</Button>
                    <Button onClick={handleSavePoll}>Save</Button>
                    {/* <Button onClick={handleClearFunction}>Clear</Button> */}
                    <Button onClick={handleCloseFunction}>Back</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PollNode;
