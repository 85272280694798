import axios from "axios";

export async function checkSubscription(pageId, accessToken) {
    try {
        if (!pageId || !accessToken) return false;
        const url = `https://graph.facebook.com/v21.0/${pageId}/subscribed_apps?access_token=${accessToken}`;
        const response = await axios(url);
        const subscription = response.data.data;
        console.log("subscription:", subscription);

        // get subscribed fields
        const subscribedFields = subscription?.[0]?.subscribed_fields?.join(", ") || "";
        return subscribedFields;
    } catch (err) {
        return false;
    }
}
