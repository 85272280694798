import type { DocumentData, QuerySnapshot } from "firebase/firestore";

export default function mapSnapshotTS<T>(snapshot: QuerySnapshot<DocumentData>): T[] {
    if (!snapshot.empty) {
        const data = snapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
                ref: doc.ref,
            };
        }) as T[];

        return data;
    }

    return [];
}
